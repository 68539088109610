import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import ConsumerRetailPractice from "./ConsumerRetailPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const ConsumerRetail = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/retail.png"
        BannerTitle="Consumer & Retail"
        BannerText="
        We have expertise in the industry to get along with your requirements and find you the best commercial-related and other relatable legal suggestions."
      />

      <div className="md:px-16 px-5 pt-8">
        <ConsumerRetailPractice
          para1="Businesses in the consumer goods and retail industry face the challenges of a complex marketplace, including shifting consumer attitudes towards products and brands, new entrants to the sector and increasingly stringent regulation. The unprecedented growth in this sector in India is due to increasing consumer demand and different business models evolving, including D2C, B2B2C and others."
          para2="Our team is equipped to handle a broad range of legal matters. We advise clients in the operation of their businesses, protection of interests, and holistic growth optimization in many cross-border transactions."
          para3="We work with a range of businesses in the industry, be it consumer goods, consumer health, food and beverage, electronics, e-commerce etc. We are best placed to help you in dealing with business-related issues and in helping you achieve your business goals. We also offer solutions on ongoing commercial relationships and agreements, IP, franchising, licensing issues as well as safety issues."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default ConsumerRetail;
