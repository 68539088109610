import React from "react";

const InnerBanner = ({
  BackgroundImage,
  BannerText,
  BannerTitle,
  shadow,
  bdrop,
}) => {
  return (
    <div
      className="h-96 shadow-inner shadow-black innerbanner bg-cover"
      style={{
        backgroundImage: `url(${BackgroundImage})`,
        backgroundColor: "rgba(0,0,0,0.45)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div
        className={`container-default h-full flex justify-center items-center flex-col space-y-2 px-6 pt-[40px] backdrop-${bdrop} bg-${shadow}`}
      >
        <div className="md:text-5xl text-3xl text-white font-bold">{BannerTitle}</div>
        {/* <div className="text-white text-base text-center max-w-3xl   font-normal">
          {BannerText}
        </div> */}
      </div>
    </div>
  );
};

export default InnerBanner;
