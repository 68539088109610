import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import HealthCarePractice from "./HealthCarePractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const HealthCare = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/healtcare.png"
        BannerTitle="Healthcare"
        BannerText="With our legal counseling and help, one can overcome any circumstance under an authorized and proficient lawyer with abundant experience in the same field. "
      />

      <div className="md:px-16 px-5 pt-8">
        <HealthCarePractice
          para1="Our team advises Indian Companies, and multinational corporations on legal issues related to the pharmaceutical sector, manufacture of products, and wholesale distribution and helps them navigate healthcare products through the e-commerce marketplace."
          para2="We also offer our expertise to structure transactions and financing arrangements, develop strategic partnerships and licensing arrangements, and advise on complex data governance issues, including proactive privacy and cybersecurity assessments. Our experienced lawyers handle complex transactional, regulatory, and litigation matters, providing counsel and insight into the industry-specific issues in India as a whole. The diversity and synergy of our practice allow us to be efficient and targeted in our support."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default HealthCare;
