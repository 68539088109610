import React from "react";
import { Link } from "react-router-dom";

const MainBanner = () => {
  return (
    <>
      <div className="">
        <video
          autoPlay
          loop
          muted
          className="bg-blend-darken center h-screen object-cover w-full "
        >
          <source
            src="/assets/videos/india-gate.mp4"
            type="video/mp4"
            className=""
          />
        </video>
        <div className="bg-transparent p-8 absolute bottom-56 md:w-[40%] md:left-20 md:right-0 right-20 z-50">
          <p className="text-base  text-white font-bold ">
            AP & Partners was founded with a simple and compelling vision: A
            team of highly experienced lawyers focused on the most significant
            opportunities and challenges facing our clients.{" "}
          </p>
          <Link to="/about">
            <button className="readmore-btn w-44 inline-block py-1 mt-5  ">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default MainBanner;
