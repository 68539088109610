import React from "react";
import Footer from "../Components/common/Footer";
import InnerBanner from "../Components/common/InnerBanner";
import Navbar from "../Components/common/Navbar";
import Career from "../Components/Home/Career";

import JoinPage from "../Components/join/JoinPage";

const JoinUs = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/careerpage.png"
        BannerTitle="Careers"
        BannerText="We are always open to hiring some talent looking forward to a platform to upskill their present skills and dedicate their legal knowledge to benefit the clients."
      />
      <JoinPage />
      <div className="pt-16 pb-5">
        <Career />
      </div>

      <Footer />
    </div>
  );
};

export default JoinUs;
