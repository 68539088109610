import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import CorporatePractice from "./CorporatePractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Corporate = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/corporateimage.png"
        BannerTitle="Corporate"
        BannerText="“The need to find a master advocate with extreme knowledge and brilliance to handle all the intangible situations smoothly ends with AP & Partners.”"
      />
      <div className="px-5 w-auto lg:px-16 pt-8">
        <CorporatePractice
          para1="Like our clients, our lawyers are ambitious, creative, and enterprising and embrace the most complex and cutting-edge commercial ventures, practices, and structures."
          para2="We deliver the full range of legal services for corporate transactions including mergers, acquisitions, private equity, and venture capital transactions."
          para3="Our rigorous task-force-based approach allows us to deliver impactful, and practical advice in a seamless manner. Our transactions team benefits from the diverse experience of our lawyers and a collegiate internal system designed to foster high-quality outcomes."
          para4="Our clients include domestic and international public and private companies, ranging from entrepreneurial start-ups to mature multinational corporations. We regularly advise significant financial institutions, insurance companies, pension funds, and private equity/venture capital funds."
          para5="We also represent a number of major corporations on their corporate governance matters, and India entry strategies."
        />
      </div>
      
    {/* <div className="pb-10">
    <Career/>
    </div> */}
    

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Corporate;
