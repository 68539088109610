import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import PrivateEquityPractice from "./PrivateEquityPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const PrivateEquity = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/PrivateEquity&VentureCapital.jpg"
        BannerTitle="Private Equity and Venture Capital"
        BannerText="
        The only legally right thing is a registered document that showcases your argument as an official and legitimate form. AP & Partners are professionals to form one for your necessity.
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <PrivateEquityPractice
          para1="Our clients include private equity funds, venture capital firms, general partners, managers, and investors. We assist our clients from the stage of initial investment until exit, advise on structuring the transaction, draft definitive documentation, undertake due diligence, and offer guidance on a wide range of associated legal and commercial issues."
          para2="We help our clients execute complex domestic and cross-border transactions. We help our clients in drafting, negotiating, and finalizing all sorts of transaction documents. We also assist in regulatory filings and/or applications necessary for transactions."
          para3="We have worked on investments across sectors including B2B commerce, B2C commerce, consumer tech, e-commerce, education, edtech, EV, financial services, fintech, food tech, gaming, fantasy sports, healthcare, heavy industry, hospitality, information technology including hardware and software, ITES, infrastructure, insurance, IoT, manufacturing, media and entertainment, mobility, pharmaceuticals and life sciences, retail, real estate, SAAS, social commerce, sustainable urban solutions and transportation."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default PrivateEquity;
