import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import EmployementPractice from "./EmployementPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Employment = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Employment.jpg"
        BannerTitle="Employment"
        BannerText="“We work with our brains to keep you healthy in the fields with your legally active and proficient advocate partners to resolve all the problems.” "
      />

      <div className="md:px-16 px-5 pt-8">
        <EmployementPractice
          para1="We deliver pragmatic solutions to help employers develop employment and benefit policies and programs that best fit the needs of their employees."
          para2="Our lawyers look to provide practical and actionable advice to employers, helping them make decisions and take action to manage litigation risk, promote culture and values, and achieve specific business objectives."
          para3="Supported by our other practice areas, we pool our areas of strength to address the full range of legal and practical issues involved in all aspects of the employer-employee relationship."
          para4="We regularly advise public and private companies, senior executives, management teams, and boards of directors, on the full range of employment and employee benefits issues that arise day-to-day as well as in transactional and restructuring contexts. We have vast experience in assisting clients with and conducting internal investigations in harassment cases and cases concerning violations of company policies."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Employment;
