import React from "react";
import Slider from "react-slick";
const QuoteSlider = () => {
  // Slder Js
  var SliderTstmonial = {
    dots: true,
    infinite: true,
    speed: 4000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        SliderTstmonial: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        SliderTstmonial: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        SliderTstmonial: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="quote-bg">
      <div className="md:w-[70%] space-y-8 mx-auto text-primary pt-20">
        <Slider {...SliderTstmonial}>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-xl py-2   font-normal text-center">
            “AP &amp; Partners is a strong partner for anyone looking to understand and navigate the Indian market.”
            </blackquote>
            <p className="text-sm   pt-3 text-center">
              -India Business Law Journal, (2020)
            </p>
          </div>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-xl py-2   font-normal text-center">
            “AP &amp; Partners helps identify creative solutions in various difficult situations.”
            </blackquote>
            <p className="text-sm   pt-3 text-center">
            -India Business Law Journal, (2020)
            </p>
          </div>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-lg py-2   font-normal text-center">
            “Very responsive, aligned objectives with the client, pragmatic approach.”
            </blackquote>
            <p className="text-sm   pt-3 text-center">
              -International Financial Law Review (2020)
            </p>
          </div>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-lg py-2   font-normal text-center">
              "Provides strategic white-collar criminal defence and regulatory support to a client base comprising mid-tier corporates and investment funds."
            </blackquote>
            <p className="text-sm   pt-3 text-center">
              -Legal 500 While Collar Crime Rankings, (2020)
            </p>
          </div>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-lg py-2   font-normal text-center">
              "Award winning law firm – 2020 &amp; 2021."
            </blackquote>
            <p className="text-sm   pt-3 text-center">
              -India Business Law Journal Indian Law Firm Awards
            </p>
          </div>
          <div className="slide slide1 flex flex-col justify-center items-center text-center">
            <blackquote className="text-lg py-2   font-normal text-center">
            “Sharp, very hands-on and solution-oriented. Keeps things calm in high-stress situations.”
            </blackquote>
            <p className="text-sm   pt-3 text-center">
              -International Financial Law Review (2020)
            </p>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default QuoteSlider;
