import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import SpecialSitutationsPractice from "./SpecialSitutationsPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const SpecialSitutations = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/corporateimage.png"
        BannerTitle="Special Situations"
        BannerText="Our team of experienced lawyers brings vast expertise and judgment to legal representation in fraud, white collar crime and other special situations.   "
      />

      <div className="px-5 md:px-16 pt-8">
        <SpecialSitutationsPractice
          para1="Our team of experienced lawyers brings vast experience in relation to the handling of fraud, white-collar crimes, and other special situations including complex shareholder disputes."
          para2="Our integrated special situations team typically consists of a task force comprising lawyers from different practice areas viz. dispute resolution attorneys and corporate lawyers coming together to work on a complex matter involving criminal, corporate, bankruptcy, and exchange control laws."
          para3="We employ our deep experience in the representation of clients facing parallel civil and criminal investigations/proceedings to achieve robust and well-coordinated results on all fronts"
          para4="We also frequently counsel clients in relation to government action, including regulatory counselling on industry risk areas. Our lawyers understand how prosecutors, investigators, and regulators approach industry-specific legal and regulatory issues, and this expertise is utilised to effectively advise our clients."
          para5="Many of the cases we handle draw public attention due to the high-profile nature of the work, as well as the impact they might have on our clients."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default SpecialSitutations;
