import React from "react";
import { Link } from "react-router-dom";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";

const TeamPage = () => {
  return (
    <div className="pt-10 pb-2 px-10">
      <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-col-1 gap-20  md:pt-20 md:space-y-0 space-y-6 pb-10 ">
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
          <img
            class="w-full"
            src="/assets/images/Anand.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />
          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-2 mt-3">
              Anand Prasad
            </div>
            <div class=" text-xl text-center mb-1 text-black">
              Senior Partner
            </div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +3456123-4567
              </a>
            </div> */}
            <div className="py-1 mx-4 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-black font-medium"
              >
                anand.prasad@appartners.in
              </a>
            </div>
            <Link
              to="/anand-prasad"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
          <img
            class="w-full"
            src="/assets/images/Arjun.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />
          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-2 mt-3">
              Arjun Sinha
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +91-1142594444
              </a>
            </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-black font-medium"
              >
                arjun.sinha@appartners.in
              </a>
            </div>
            <Link
              to="/arjun-sinha"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
          <img
            class="w-full"
            src="/assets/images/Dushyant.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />
          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-2 mt-3">
            Dushyant Kumar
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +91-1142594444
              </a>
            </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-black font-medium"
              >
                 dushyant.kumar@appartners.in
              </a>
            </div>
            <Link
              to="/dushyant-kumar"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
          <img
            class="w-full"
            src="/assets/images/Harshit.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />
          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-1 mt-3">
              Harshit Anand
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +3456123-4567
              </a>
            </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-black font-medium"
              >
                harshit.anand@appartners.in
              </a>
            </div>
            <Link
              to="/harshit-anand"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center mb-8"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white">
          <img
            class="w-full"
            src="/assets/images/moksh.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />
          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-1 mt-3">
              Moksha Bhat
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
                <div className="pt-1">
                  <BsPhone className="text-primary" />
                </div>
                <a href="tel:2321234567" className="text-black font-medium ">
                  +91-1142594444
                </a>
              </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-black font-medium"
              >
                moksha.bhat@appartners.in
              </a>
            </div>
            <Link
              to="/moksha-bhat"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center mb-8"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white ">
          <img
            class="w-full"
            src="/assets/images/sandeepimg.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />

          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-1 mt-3">
              Sandeep Das
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +91-1142594444
              </a>
            </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:sandeep.das@appartners.in"
                className="text-black font-medium"
              >
                sandeep.das@appartners.in
              </a>
            </div>
            <Link
              to="/sandeep-das"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center mb-8"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        <div class="max-w-sm rounded overflow-hidden shadow-lg bg-white ">
          <img
            class="w-full"
            src="/assets/images/vishalimg.png"
            alt="Sunset in the mountains"
            style={{ objectFit: "scale-down" }}
          />

          <div class=" ">
            <div class="font-bold text-xl text-center text-black mb-1 mt-3">
            Vipin Tyagi
            </div>
            <div class=" text-xl text-center mb-1 text-black">Partner</div>
            {/* <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <BsPhone className="text-primary" />
              </div>
              <a href="tel:2321234567" className="text-black font-medium ">
                +91-1142594444
              </a>
            </div> */}
            <div className="py-1 flex gap-3 justify-center">
              <div className="pt-1">
                <AiOutlineMail className="text-primary" />
              </div>
              <a
                href="mailto:vipin.tyagi@appartners.in"
                className="text-black font-medium"
              >
               vipin.tyagi@appartners.in
              </a>
            </div>
            <Link
              to="/vishal-tyagi"
              style={{ display: "flex" }}
              className="pt-1 justify-center align-center items-center text-center mb-8"
            >
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>
              </div>
              <p className="text-primary font-bold ml-2">View Profile</p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="black"
                className="w-8 h-8 mt-1 ml-1"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamPage;
