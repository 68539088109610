import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer class="text-center lg:text-left  text-gray-600">
      <div class="px-16 flex justify-center items-center lg:justify-between p-5 border-b border-gray-300">
        <div class="mr-12 hidden lg:block">
          <span className="text-3xl text-secondary font-semibold drop-shadow-lg">
            <Link to="/" className="logo">
              <img
                src="/assets/images/logo.png"
                alt="logo"
                className="cursor-pointer drop-shadow-2xl w-52 h-16"
              />
            </Link>
          </span>
        </div>
       
        <div class="flex justify-center">
          <a
            href="https://in.linkedin.com/company/appartners"
            class="mr-6 w-10 h-10 rounded-lg bg-secondary flex hover:bg-primary justify-center items-center text-white hover:bg-third"
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fab"
              data-icon="linkedin-in"
              class="w-5"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 448 512"
            >
              <path
                fill="currentColor"
                d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
              ></path>
            </svg>
          </a>
        </div>
      </div>

      <div class="text-center p-6 text-sm bg-secondary text-white  font-normal">
        <span>Copyright © 2023 AP & Partners All Rights Reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
