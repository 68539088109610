import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import AutomotivePractice from "./AutomotivePractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Automotive = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Automotives.jpg"
        BannerTitle="Automotives"
        BannerText="
        Are you looking forward to putting your foot into the vast automobile industry? Or have you been trapped in the sector’s downfall for a long time? Find us for help in any case.
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <AutomotivePractice
          para1="As the automotive industry evolves with the adoption of breakthrough innovations, from next-generation electric vehicle batteries to advanced driver-assistance and automated vehicle systems, we help companies develop and acquire new technologies, expand into new markets, refine global supply chains, and capitalize on emerging trends."
          para2="Our automotive practice acts for OEMs and suppliers across the industry. We have experience across all sectors of the automotive industry, from passenger-car and light-truck manufacturers to heavy-truck makers as well as various players in the EV space from manufacturers to EV based service providers. "
          para3="The practice covers the full spectrum of legal support in mergers and acquisitions, data protection, intellectual property rights, commercial transactions (including licensing and collaborations), equity and debt funding, regulatory, dispute resolution, employment as well as anti-trust issues."
          para4="Our team represents major automotive manufacturers, parts suppliers, and distributors, as well as companies operating in the research and development of new, cleaner and more efficient technologies. The group also represents the investors that fuel innovation in the industry."
        />
      </div>
      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Automotive;
