import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
const RegulatingAggregators = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02258.jpg"
        BannerTitle="Our Analysis"
        BannerText="We have a proficient team of masterful legal advisors and advocates to find you rigid and legitimate solutions. Our analytical team researches and then figures out the perfect strategy."
        bdrop="brightness-50"
        shadow="black/30"
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16 px-5  mx-auto md:gap-6 md:py-20">
        <div className="md:col-span-7 flex gap-5 flex-col  bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="font-serif  text-secondary text-2xl font-medium">
            Regulating Aggregators: A Framework for the Future
          </h2>
          <p className="font-sans  text-justify">
            Aggregators such as Uber and Ola have become household names over
            the past decade, propelled by astronomical growth. But clarity
            concerning their status in law was lacking until recently, due to
            which issues such as weak passenger safety went largely unaddressed.
            For years, concerned citizens expressed the need to check such abuse
            via appropriate regulatory action.
          </p>
          <p className="font-sans  text-justify">
            To set the ball rolling, the central government issued an advisory
            in 2015 calling for the regulation of aggregators. While some states
            subsequently took up the cause of aggregator regulation by framing
            rules, legal clarity remained stymied by the lack of any legislative
            provision explicitly providing for regulation of aggregators.[1]
            Even the Motor Vehicles Act (“MVA”), which provides for regulation
            of all classes of individuals relevant to the transportation sector
            like drivers and cab companies had virtually nothing to say about
            aggregators.
          </p>
          <p className="font-sans  text-justify">
            Fortunately, legal clarity has now been achieved through a 2019
            amendment of the MVA.[2] The amended MVA achieves clarity by
            providing for: 1) a definition of aggregators 2) licensing of
            aggregators and 3) the division of powers between states and the
            Centre over regulation of aggregators.
          </p>
          <p className="font-sans  text-justify">
            1.Definition: The term ‘aggregator’ is now defined in the MVA.
            Section 2(1A) provides that aggregators constitute “a digital
            intermediary or marketplace for a passenger to connect with a driver
            for the purpose of transportation.” This clear and concise
            definition, providing that aggregators connect drivers and
            passengers, settles much controversy going back to at least 2015.
            Over the years, courts, regulators and policy experts have offered
            contrary and competing opinions on the definition of aggregators.
            While some have stated that aggregators are taxi service companies,
            others have asserted that they are advertisers on behalf of vehicle
            permit owners.[3]
          </p>
          <p className="font-sans  text-justify">
            Going forward, the crisp MVA definition will be a firm guide to
            states framing rules for aggregators.[4]
          </p>
          <p className="font-sans  text-justify">
            2. Licensing: Section 93 of the MVA now provides for licensing of
            aggregators. Previously, states occasionally relied on other
            provisions such as section 74 for aggregator licensing.[5] By
            explicitly providing for licensing under Section 93, the amendment
            has brought clarity.[6] Indeed, courts have confirmed the importance
            of designating one section in the MVA for licensing.[7]
          </p>
          <p className="font-sans  text-justify">
          Unfortunately, in recent times the Delhi Government has deviated from this framework to formulate an Aggregators Scheme under section 67(3) of the MVA.[8] Permitting states to license aggregators under two different sections – 67(3) and 93 – is redundant and counterproductive. It represents a repudiation of the structure of the MVA, which seeks to confine power to license various entities [including aggregators] to watertight sections that don’t overlap with each other.[9]
          </p>
          <p className="font-sans  text-justify">
          3. Federalism: Section 93 provides that while framing rules for aggregators, states are not bound by central government guidelines that may be issued from time to time.[10] While the initial draft amendment provided for mandatory adherence to central government guidelines, Parliament ultimately decided to vest regulatory power with the states.[11] This brings further clarity to states regarding their ability to regulate aggregators by giving them a free hand, unencumbered by the prospect of a meddling Centre.
          </p>
          <p className="font-sans  text-justify">
          Thus, as more states look to regulate aggregators the legislative clarity provided by the amended MVA will be of invaluable use. It dispels the uncertainty that once beclouded the regulation of aggregators. Furthermore, by encouraging states to take the lead in regulating aggregators, the new framework ensures that core concerns such as the safety of passengers will be fulsomely addressed in the future.
          </p>
          <p className="font-sans  text-justify">
          by Sachin Dhawan [Senior Advisor] and Saptarshi Das [Associate]
          </p>
          <p className="w-36 bg-black h-[2px] mx-auto"></p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[1]</span> Consequently, some High Courts upheld the power of states to regulate aggregators while others did not. See: ANI Technologies Pvt. Ltd. v. State of Uttarakhand and Ors. AIR 2015 UK 90 and Satish N. v State of Karnataka 2017 ILR (Kar.) 735
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[2]</span> The Motor Vehicles (Amendment) Act, 2019.
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[3]</span> See: ANI Technologies Pvt. Ltd. vs. Government of NCT of Delhi and Ors. (DELHC): MANU/DE/2240/2015; Satish N. v State of Karnataka 2017 ILR (Kar.) 735
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[4]</span> See: State of Tamil Nadu v. P. Krishnamurthy and Ors (2006) 4 SCC 517; Satish N. v State of Karnataka 2017 ILR (Kar.) 735 (As delegated legislation cannot exceed the scope of the parent law, the definition of aggregators in states’ rules must conform to the definition contained in the MVA.)
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[5]</span> Transport Department, Government of Haryana, NCR Motor Cab (Taxi) Scheme, 2016, 46977/AT-2/ST-III (Notified on 9.8.2016)
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[6]</span> While the Centre’s advisory on aggregators also called for licensing under section 93 it was not binding on states.
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[7]</span> See: Satish N. v State of Karnataka 2017 ILR (Kar.) 735
          </p>
          <p className="font-sans  text-justify overflow-hidden">
          <span className="text-secondary" >[8]</span> Draft Motor Vehicle Aggregator Scheme, 2021, Transport Department, GNCTD, available at https://transport.delhi.gov.in/sites/default/files/All-PDF/Draft_Aggregator_Scheme_.pdf
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[9]</span> Additionally, the Delhi Aggregators Scheme violates the MVA by seeking to license goods delivery aggregators in addition to passenger aggregators. As goods delivery aggregators fall afoul of the definition of aggregators in the MVA, they cannot be subject to state licensing power without an amendment to the MVA.
          </p>
          <p className="font-sans  text-justify">
          <span className="text-secondary" >[10]</span> Motor Vehicles Act 1988, Section 93 proviso 1, Ins. by Act 32 of 2019, s. 36, (w.e.f. 27-11-2020).
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[11]</span> STANDING COMMITTEE ON TRANSPORT, TOURISM AND CULTURE, Motor Vehicles (Amendment) Bill, 2016, Two Hundred Forty Third Report, ¶ 133 (February, 2017)
          </p>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
      </div>
      <QuoteSlider />
    </div>
  );
};

export default RegulatingAggregators;
