import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import { Link } from "react-router-dom";
const knowledge1 = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02241.png"
        BannerTitle=" Knowledge "
        BannerText="The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you. "
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16  mx-auto md:gap-6 md:py-20">
        <div className="col-span-7 flex gap-5 flex-col items-start bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="  text-secondary text-xl leading-relaxed font-medium">
          India Business Law Journal 2023-2024 A-List Icon
          </h2>
          <p className="  text-justify">
          Our Senior Partner Anand Prasad continues to be recognized as an Icon in India Business Law Journal's A-List of top legal professionals in India.

          </p>
          <a Target="_blank" href="https://www.linkedin.com/posts/appartners_our-senior-partner-anand-prasad-continues-activity-7143175447483232256-F77D?utm_source=share&utm_medium=member_desktop">
            <button className=" font-smedium text-primary  hover:underline duration-500" >
              Read More
            </button>
          </a>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default knowledge1;
