import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import { Link } from "react-router-dom";
const Blog2 = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02241.png"
        BannerTitle="Updates and Analysis"
        BannerText="The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you."
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16  mx-auto md:gap-6 md:py-20">
        <div className="col-span-7 flex gap-5 flex-col items-start bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="  text-secondary text-xl leading-relaxed font-medium">
          Our partner Sandeep Das has been recognized as one of India’s top disputes lawyers
          </h2>
          <p className="  text-justify">
          Happy to share that our partner Sandeep Das has been recognized as one of India’s top disputes lawyers 2021 by Asian Legal Business
          </p>
          {/* <p className="  text-justify">
            Capt. Sanjay Gahlot will provide strategic business and regulatory
            advice to the members and clients of the firm.
          </p> */}
          <a Target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6860831778001743872">
            <button className=" font-smedium text-primary  hover:underline duration-500">
              Read More
            </button>
          </a>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default Blog2;
