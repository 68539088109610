import { Routes, Route, BrowserRouter } from "react-router-dom";
import "./App.css";
import About from "./Components/Home/About";
import AboutUs from "./Pages/AboutUs";
import ContactUs from "./Pages/ContactUs";
import Corporate from "./Pages/Corporate";
import Home from "./Pages/Home";
import JoinUs from "./Pages/JoinUs";
import Practices from "./Pages/Practices";
import Media from "./Pages/Media";
import Team from "./Pages/Team";
import Analysis from "./Pages/Analysis";
import AnandDetail from "./Pages/AnandDetail";
import RegulatingAggregators from "./Pages/RegulatingAggregators";
import Blog1 from "./Pages/Blog1";
import MokshaDetail from "./Pages/MokshaDetail";
import ArjunDetail from "./Pages/ArjunDetail";
import SandeepDetail from "./Pages/SandeepDetail";
import Vishaldetail from "./Pages/Vishaldetail";
import HarshitDetail from "./Pages/HarshitDetail";
import Commercial from "./Pages/Commercial";
import DisputesAdvisory from "./Pages/DisputesAdvisory";
import Litigation from "./Pages/Litigation";
import WhiteCollar from "./Pages/WhiteCollar";
import Arbitration from "./Pages/Arbitration";
import Technology from "./Pages/Technology";
import Regulatory from "./Pages/Regulatory";
import RealEstate from "./Pages/RealEstate";
import MiningMeta from "./Pages/MiningMeta";
import Defence from "./Pages/Defence";
import Industrial from "./Pages/Industrial";
import FinancialServices from "./Pages/FinancialServices";
import ConsumerRetail from "./Pages/ConsumerRetail";
import Infrastructure from "./Pages/Infrastructure";
import Sectors from "./Pages/Sectors";
import Blog2 from "./Pages/Blog2";
import Blog3 from "./Pages/Blog3";
import Blog4 from "./Pages/Blog4";
import Blog5 from "./Pages/Blog5";
import Blog6 from "./Pages/Blog6";
import Blog7 from "./Pages/Blog7";
import Blog8 from "./Pages/Blog8";
import Knowledge1 from "./Pages/knowledge1";
import SpecialSitutations from "./Pages/SpecialSitutations";
import Employment from "./Pages/Employement";
import Tmt from "./Pages/Tmt";
import Automotive from "./Pages/Automotive";
import Communication from "./Pages/Communication";
import Education from "./Pages/Education";
import Energy from "./Pages/Energy";
import HealthCare from "./Pages/HealthCare";
import PrivateEquity from "./Pages/PrivateEquity";
import CourtShields from "./Pages/CourtShields";
import Disputes from "./Pages/Disputes";
import ScrollToTop from "./Components/hooks/ScroolToTop";
import WhiteCollarDefence from "./Pages/WhiteCollarDefence";
import EntertainmentSports from "./Pages/EntertainmentSports";
import Kamaljeetdetail from "./Pages/Kamaljeetdetail";
import Dushyantdetail from "./Pages/Dushyantdetail";
import Knowledge from "./Pages/knowledge";

function App() {
  return (
    <div className="App">
<ScrollToTop>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/team" element={<Team />} />
        <Route path="/anand-prasad" element={<AnandDetail />} />
        <Route path="/moksha-bhat" element={<MokshaDetail />} />
        <Route path="/arjun-sinha" element={<ArjunDetail />} />
        <Route path="/dushyant-kumar" element={<Dushyantdetail />} />
        <Route path="/kamaljeet-singh" element={<Kamaljeetdetail />} />
        <Route path="/sandeep-das" element={<SandeepDetail />} />
        <Route path="/vishal-tyagi" element={<Vishaldetail />} />
        <Route path="/harshit-anand" element={<HarshitDetail />} />
        <Route path="/practices" element={<Practices />} />
        <Route path="/sectors" element={<Sectors/>} />
        <Route path="/corporate" element={<Corporate />} />
        <Route path="/commercial" element={<Commercial />} />
        <Route path="/pre-disputes-advisory" element={<DisputesAdvisory />} />
        <Route path="/litigation" element={<Litigation />} />
        <Route
          path="/white-collar-and-enforcement-actions"
          element={<WhiteCollar />}
        />
        <Route path="/arbitration" element={<Arbitration />} />
        <Route path="/technology" element={<Technology />} />
        <Route path="/special-situations" element={<SpecialSitutations/>} />
        <Route path="/employment-benefits" element={<Employment/>}/>
        <Route path="/disputes" element={<Disputes/>} />
        <Route path="/tmt" element={<Tmt/>}/>
        <Route path="/regulatory-and-compliance" element={<Regulatory />} />
        <Route path="/real-estate" element={<RealEstate />} />
        <Route path="/mining-metal" element={<MiningMeta />} />
        <Route path="/defence" element={<Defence />} />
        <Route path="/industrial-manufacturing" element={<Industrial />} />
        <Route path="/financial-services" element={<FinancialServices />} />
        <Route path="/white-collar-defence" element={<WhiteCollarDefence />} />
        <Route path="/entertainment-sports" element={<EntertainmentSports />} />
        <Route path="/consumer-retail" element={<ConsumerRetail />} />
        <Route path="/infrastructure-transport" element={<Infrastructure />} />
        <Route path="/automotive" element={<Automotive />} />
        <Route path="/communication-and-media" element={<Communication/>} />
        <Route path="/education" element={<Education/>}/>
        <Route path="/energy-and-natural-resources" element={<Energy/>}/>
        <Route path="/health-care-and-life-science" element={<HealthCare/>}/>
        <Route path="/pivate-equity" element={<PrivateEquity/>}/>
        <Route path="/media" element={<Media />} />
        <Route
          path="/we-are-delighted-to-welcome-on-board-capt-sanjay-gahlot-irsr"
          element={<Blog1 />}
        />
        <Route
          path="/Our-partner-Sandeep-Das-has-been-recognized-as-one-of-Indias-top-disputes-lawyers"
          element={<Blog2 />}
        />
        <Route
          path="/AP-Partners-have-been-recognized-as-a-notable-firm-by-asialaw"
          element={<Blog3 />}
        />
        <Route
          path="/Our-partner-Arjun-Sinha-will-be-sharing-insights-on-music-licensing-deal"
          element={<Blog4/>}
        />
        <Route
          path="/AP-Partners-have-been-recognized-once-again-as-a-notable-firm-by-IFLR1000"
          element={<Blog5 />}
        />
        <Route
          path="/E-commerce-rules-cast-cloud-over-Amazon-Walmart-and-local-rivals"
          element={<Blog6 />}
        />
        <Route
          path="/AP-Partners-recognized-as-an-award-winning-law-firm-by-IBLJ"
          element={<Blog7 />}
        />
        <Route
          path="/Indias-New-Tech-Laws-for-Social-Media"
          element={<Blog8 />}
        />
        <Route
          path="/Knowledge-Detail-Page"
          element={<Knowledge1 />}
        />
        <Route path="/update-and-analysis" element={<Analysis />} />
        <Route
          path="/regulating-aggregators"
          element={<RegulatingAggregators />}
        />
        <Route
          path="/court-shields-whatsapp-group-admins-from-vicarious-criminal-liability"
          element={<CourtShields/>}
        />
        <Route path="/join-us" element={<JoinUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/knowledge" element={<Knowledge />} />
      </Routes>
      </ScrollToTop>
    </div>
  );
}

export default App;
