import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DisputesPractice from "./DisputesPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Disputes = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/disputes.jpg"
        BannerTitle="Dispute Resolution"
        BannerText="We have a notch to minimally solve all the disputes with our legal advisory team. If you are stuck in any, find the masters of the matter right away, which have solved all sized cases.  "
      />

      <div className="px-5 md:px-16 pt-8">
        <DisputesPractice
          para1="Our disputes practice represents clients facing challenging and high-stakes legal disputes. The deep experience of our litigators, coupled with the broader capabilities of the firm, gives us the ability to help our clients navigate through difficult situations."
          para2="Many of the cases we have handled have often drawn public attention due to the high-profile nature of the work, as well as the impact it might have on our clients. We look to provide advice that is both practical and commercial."
          para3="Our team has significant experience appearing before courts, regulatory bodies, and arbitral tribunals. We have represented clients in a wide array of corporate-commercial matters before the Supreme Court of India, various High Courts, National Company Law Tribunals, and district courts amongst others."
          para4="Our team also has significant experience in all aspects of corporate criminal defence and enforcement-related litigation, including internal investigations, management of parallel criminal and civil proceedings, and extraditions. We represent clients in investigations or prosecution by central, state and local authorities, including the Serious Fraud Investigation Office, the Enforcement Directorate, the Central Bureau of Investigation, and the Securities Exchange Board of India."
          para5="We frequently counsel clients long before government action, including regulatory counselling on industry risk areas."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Disputes;
