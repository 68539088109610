import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import { Link } from "react-router-dom";
const Blog6 = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02241.png"
        BannerTitle="Updates and Analysis"
        BannerText="The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you."
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16  mx-auto md:gap-6 md:py-20">
        <div className="col-span-7 flex gap-5 flex-col items-start bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="  text-secondary text-xl leading-relaxed font-medium">
          E-commerce rules cast cloud over Amazon, Walmart and local rivals
         </h2>
          <p className="  text-justify">
          New Delhi: New e-commerce rules will raise costs for all online retailers but pasticularly Amazon and walmart’s Flipkart as they may have to review their business structures, senior industry sources told Reuters.

The misistry of consumer affairs outlined plpan on Monday which include limiting “flash sales” by online retailerrs, reining in a private label push, compellinng them to appoint compliance officers and impose a “fall-back liability” if a seller is negligent.

The new rules are expected to have an impact across the board in an e-retail market India forecasts will be worth $200 billion by 2026, with players includinng from Tata’s BigBasket, Reliance Industries’ JioMart and Softbank-backed Snapdeal to market leaders Amazon and Flipkart.

</p>
          {/* <p className="  text-justify">
            Capt. Sanjay Gahlot will provide strategic business and regulatory
            advice to the members and clients of the firm.
          </p> */}
          <a Target="_blank"href="https://timesofindia.indiatimes.com/business/india-business/e-commerce-rules-cast-cloud-over-amazon-walmart-and-local-rivals/articleshow/83756190.cms">
            <button className=" font-smedium text-primary  hover:underline duration-500">
              Read More
            </button>
          </a>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
        
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default Blog6;
