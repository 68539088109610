import React from "react";
import Footer from "../Components/common/Footer";
import InnerBanner from "../Components/common/InnerBanner";
import Navbar from "../Components/common/Navbar";
import Career from "../Components/Home/Career";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const HarshitDetail = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      {/* <InnerBanner BackgroundImage="/assets/images/aboutbanner.png" /> */}

      <div className=" px-6 py-10 w-full">
        <div className="grid lg:grid-cols-12 grid-cols-6 w-full  lg:gap-10">
          <div className="lg:col-span-9 col-span-12 shadow-md p-6 rounded-md">
            <div className="py-1 mt-8">
              <h4 className="font-bold py-1 text-6xl text-primary">
                Harshit Anand
              </h4>
              <h4 className="py-1  font-medium text-lg">Partner</h4>
            </div>
            <p className=" font-semibold py-2 text-justify leading-relaxed">
              Harshit is a partner in our corporate practice group focusing on
              mergers and acquisitions, joint ventures and private equity
              matters. He has extensive experience of working on high-value and
              complex transactions having worked on multiple big-ticket domestic
              and cross-border matters.
            </p>

            <p className="  py-3 text-justify leading-relaxed">
              Harshit's experience also includes some of the biggest public M&A
              matters in recent years in India. Harshit has also worked as a
              Foreign Lawyer (on secondment) at the Tokyo office of Mori Hamada
              & Matsumoto.
            </p>

            <p className="  py-3 text-justify leading-relaxed">
              Harshit has previously worked as a legal advisor with the
              government-owned Oil and Natural Gas Corporation (India’s largest
              oil and gas exploration company). This experience of having worked
              in the legal department of a government corporation provides him
              with a unique insight into the working of government organizations
              in India.
            </p>
          </div>

          <div className="lg:col-span-3 col-span-8 mx-auto">
            <div className="col-span-3">
              <div className="mt-14">
                <img src="/assets/images/Harshit.png" />
              </div>
              {/* <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <BsPhone className="w-5 h-5" />
                </div>
                <a
                  href="tel:2321234567"
                  className="text-secondary font-medium hover:text-primary"
                >
                  +91-1142594444
                </a>
              </div> */}

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:sandeep.das@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  harshit.anand@appartners.in
                </a>
              </div>
              <div>
                <a href="https://www.linkedin.com/in/hza/">
                  <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                    <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fab"
                        data-icon="linkedin-in"
                        class="w-5"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="currentColor"
                          d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                        ></path>
                      </svg>
                    </div>

                    <div>View Profile</div>
                  </div>
                </a>
              </div>
              {/* <h3 className="text-2xl font-bold">Practice(s)</h3>

              <div className="py-4">
                <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Corporate
                </h3>
                <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Employment
                </h3>
                <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Special Situations
                </h3>
                <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Technology, Media, and Telecommunications
                </h3>
                
              </div> */}
              <div className="py-4">
                {" "}
                <h4 className="text-2xl font-bold">Education</h4>
                <ul className="px-5 py-3">
                  <li className="py-1  list-disc">
                    BBA LL.B. (Business Law Hons.), National Law University,
                    Jodhpur
                  </li>
                  <li className="py-1  list-disc">
                    Bar Admissions and Membership: Admitted to practice in India
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Career />

      <Footer />
    </div>
  );
};

export default HarshitDetail;
