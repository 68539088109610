import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import EnergyPractice from "./EnergyPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Energy = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/energy.png"
        BannerTitle="Energy and Natural Resources"
        BannerText="
        If you are looking forward to finding the perfect guidance in the legal sector for the ENR industry, then find us right here with virtuosity in the same and more.
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <EnergyPractice
          para1="We have deep expertise in energy, mining, and infrastructure matters. We act on a range of corporate transactions, including mergers, acquisitions, and joint ventures in these sectors."
          para2="We also assist project developers, sponsors, and contractors on all aspects of project contracts, including concessions, construction, civil engineering, equipment, supply, and O&M contracts."
          para3="We also have significant experience in enforcement actions in the energy, mining, and infrastructure sectors."
          para4=""
        />
      </div>

      <div className="pb-10">
        <Career />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Energy;
