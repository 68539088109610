import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const DisputesAdvisory = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Pre-Disputes Advisory"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="Our disputes practice represents clients facing unusually challenging, high-stakes legal disputes. The deep experience of our litigators—coupled with the broader capabilities of the firm—gives us the ability to help our clients navigate through difficult situations."
          para3="Many of the cases we have handled have often drawn public attention due to the high-profile nature of the work, as well as the impact it might have for our clients. We look to provide advice that is both practical and commercial."
          para4="Our team has significant experience appearing before courts and arbitral tribunals. We have represented clients in a wide array of corporate-commercial matters before the Supreme Court of India, the High Courts, National Company Law Tribunals, and district courts.

          Our team also has market leading experience in all aspects of corporate criminal defense and enforcement-related litigation, including internal investigations, management of parallel criminal and civil proceedings and extraditions. We represent clients in investigations or prosecution by central, state and local authorities, including the Serious Fraud Investigation Office, Enforcement Directorate, the Central Bureau of Investigation, and Securities Exchange Board of India. 
          "
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default DisputesAdvisory;
