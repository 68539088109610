import React from "react";
import Card from "../Components/common/blogs/Card";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import Footer from "../Components/common/Footer";
import InnerBanner from "../Components/common/InnerBanner";
import Navbar from "../Components/common/Navbar";
import QuoteSlider from "../Components/common/QuoteSlider";

const Media = () => {
  return (
    <div>
      <Navbar />

      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02493.png"
        BannerTitle="Our Media"
        BannerText="
        The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you.
         "
      />
      <div className="grid lg:grid-cols-3 md:grid-cols-2  grid-cols-1 px-10 mx-auto gap-6 pt-20">
          {" "}
          <Card
            Image="/assets/images/blog/blog-1.jpeg"
            Title=" We are delighted to welcome on board Capt. Sanjay Gahlot (IRS)(R)"
            Description="We are delighted to welcome on board Capt.  Sanjay Gahlot (IRS)(R) as a senior advisor with AP &..."
            Date="26/10/2021"
            url="/we-are-delighted-to-welcome-on-board-capt-sanjay-gahlot-irsr"
          />
          <Card
            Image="https://appartners.in/wp-content/uploads/2021/11/Sandeep-D.-Das.jpeg"
            Title=" Our partner Sandeep Das has been recognized as one of India’s top disputes lawyers"
            Description="Happy to share that our partner Sandeep Das has been recognized as one of India’s top disputes ..."
            Date="12/08/2021"
            url="/Our-partner-Sandeep-Das-has-been-recognized-as-one-of-Indias-top-disputes-lawyers"
          />
        <RecentBlogs />
      </div>
      <div className="grid lg:grid-cols-3 px-10  md:grid-cols-2  grid-cols-1 gap-8 pt-20 mx-auto">
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/09/Slide-2.jpeg"
          Title="AP & Partners have been recognized as a notable firm by asialaw"
          Description="We are pleased to share that AP & Partners has been recognized as a notable firm for #corporate ..."
          Date="30/09/2021"
          url="/AP-Partners-have-been-recognized-as-a-notable-firm-by-asialaw"
        />
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/09/AAM.jpg"
          Title="Our partner Arjun Sinha will be sharing insights on music licensing deal"
          Description="AP & Partners, renowned advisors on regulation regarding media, broadcasting, logistics and tech ..."
          Date="24/09/2021"
          url="/Our-partner-Arjun-Sinha-will-be-sharing-insights-on-music-licensing-deal"
        />
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/09/IFLR1000.jpeg"
          Title="AP & Partners have been recognized once again as a notable firm by IFLR1000 "
          Description="We are pleased to share that AP & Partners has been recognized once again as a notable firm for� ..."
          Date="16-09-2021"
          url="/AP-Partners-have-been-recognized-once-again-as-a-notable-firm-by-IFLR1000"
        />
      </div>
      <div className="grid lg:grid-cols-3  gap-8 md:grid-cols-2 grid-cols-1 px-10 py-20 mx-auto ">
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/07/E-commerce-rules.jpg"
          Title="E-commerce rules cast cloud over Amazon, Walmart and local rivals"
          Description="New Delhi: New e-commerce rules will raise costs for all online retailers but pasticularly Amazon an ..."
          Date="22/06/2021"
          url="/E-commerce-rules-cast-cloud-over-Amazon-Walmart-and-local-rivals"
        />
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/07/award-winning-law-firm.jpg"
          Title="AP & Partners recognized as an award-winning law firm by IBLJ"
          Description="Happy to share that we have been recognized by the India Business Law Journal as an award winning ..."
          Date="07/06/2021"
          url="/AP-Partners-recognized-as-an-award-winning-law-firm-by-IBLJ"
        />
        <Card
          Image="https://appartners.in/wp-content/uploads/2021/04/Linkedin-Indias-New-Tech-Laws-For-Social-Media.png"
          Title="India’s New Tech Laws for Social Media"
          Description="Our Partner, Arjun Sinha speaks to Mugdha Variyar CNBC-TV18 on the new tech laws for social media ac ..."
          Date="04-03-2021"
          url="/Indias-New-Tech-Laws-for-Social-Media"
        />
      </div>
      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Media;
