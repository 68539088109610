import React from "react";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import PracticePage from "../Components/practice/PracticePage";

const Practices = () => {
  return (
    <div>
      <Navbar />
      <PracticePage />
      <Footer />
    </div>
  );
};

export default Practices;
