import React from "react";
import Footer from "../Components/common/Footer";
import InnerBanner from "../Components/common/InnerBanner";
import Navbar from "../Components/common/Navbar";
import Career from "../Components/Home/Career";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const MokshaDetail = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Navbar />
      {/* <InnerBanner BackgroundImage="/assets/images/aboutbanner.png" /> */}

      <div className="px-6 py-10">
        <div className="flex gap-10 py-6">


        </div>

        <div className="grid lg:grid-cols-12 grid-cols-6 lg:gap-10">
          <div className="lg:col-span-9 col-span-12 shadow-md p-6 rounded-md">
            <div className="py-1">
              <h4 className="font-bold py-1 text-6xl text-primary">
                Moksha Bhat
              </h4>
              {/* <div className="w-48 h-1 bg-secondary"></div> */}
              <h4 className="py-1  font-medium text-lg">Partner</h4>


              {/* <Link to="/">
              <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fab"
                    data-icon="linkedin-in"
                    class="w-5"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                    ></path>
                  </svg>
                </div>

                <div>View Linkedin Profile</div>
              </div>
            </Link> */}
            </div>
            <p className="  py-2 text-justify font-bold leading-relaxed">
              Moksha is a corporate lawyer based in New Delhi. Moksha advises domestic and foreign companies, venture capital, private equity funds as well as senior management on various issues from formation to liquidity. Moksha regularly advises on inbound and out-bound M&A transactions, joint ventures, strategic and financial investments, and other corporate transactions.

            </p>

            <p className="  py-3 text-justify leading-relaxed">
              Moksha also advises on a broad range of disputes affecting corporations including those arising from the failure of joint ventures, claims arising out of the insolvency of corporate entities and fraud claims.

            </p>

            <p className="  py-3 text-justify leading-relaxed">
              Moksha has also worked as a Foreign Lawyer in the Zurich office of Pestalozzi, a leading Swiss law firm.

            </p>

            <p className="  py-3 text-justify leading-relaxed">
              Key clients that Moksha has represented include Mitsui, JERA Power, IFC, Leclanche SA, Mitsubishi Corporation, Mitsubishi Electric Corporation, Mitsubishi Heavy Industries, Omron, InfoEdge India Limited, InterGlobe Enterprises, and Shinryo Corporation.

            </p>

            <div>
              <h5 className="text-secondary font-medium text-lg capitalize">
                What clients say about Moksha:
              </h5>

              <ul className="py-2 px-5">
                <li className="  list-disc py-1.5 leading-relaxed">
                  “Sharp, very hands-on and solution-oriented. Keeps things calm
                  in high-stress situations” – International Financial Law
                  Review, 2020
                </li>
                <li className="  list-disc py-1.5 leading-relaxed">
                  “He is attentive and is a solution provider” – International
                  Financial Law Review, 2020
                </li>
                <li className="  list-disc py-1.5 leading-relaxed">
                  “Outstanding, commercially savvy and very responsive” – India
                  Business Law Journal 2021
                </li>
              </ul>
            </div>


          </div>

          <div className="lg:col-span-3 col-span-12 mx-auto">
            <div className="mt-14">
              <img
                src="/assets/images/Moksha.png"
              />
            </div>
            {/* <div className="py-2 flex gap-3">
              <div className="pt-1">
                <BsPhone className="w-5 h-5" />
              </div>
              <a
                href="tel:2321234567"
                className="text-secondary font-medium hover:text-primary"
              >
                +3456123-4567
              </a>
            </div> */}

            <div className="py-2 flex gap-3">
              <div className="pt-1">
                <AiOutlineMail className="w-5 h-5 " />
              </div>
              <a
                href="mailto:moksha.bhat@appartners.in"
                className="text-secondary font-medium hover:text-primary"
              >
                moksha.bhat@appartners.in
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/in/mokshabhat/?originalSubdomain=in/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
            {/* <h3 className="text-2xl font-bold">Practice(s)</h3>

            <div className="py-4">
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Corporate
              </h3>
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Employment
              </h3>
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Special Situations
              </h3>

            </div> */}
            
            <div className="py-4">
              {" "}
              <h4 className="text-2xl font-bold">Education</h4>
              <ul className="px-5 py-3">
                <li className="py-1  list-disc">
                  B.A., LL.B. (Hons.), National University of Juridical
                  Sciences, Kolkata
                </li>
                <li className="py-1 list-disc">
                  Bar Admissions and Membership: Admitted to practice in New
                  York and India
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Career />

      <Footer />
    </div>
  );
};

export default MokshaDetail;
