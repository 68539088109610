import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
const CourtShields = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02258.jpg"
        BannerTitle="Our Analysis"
        BannerText="We have a proficient team of masterful legal advisors and advocates to find you rigid and legitimate solutions. Our analytical team researches and then figures out the perfect strategy."
        bdrop="brightness-50"
        shadow="black/50"
      />
      <div className="md:grid md:grid-cols-10 md:container-ap md:px-16 mx-auto gap-6 md:py-20">
        <div className="md:col-span-7 flex gap-5 flex-col  bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="font-serif text-secondary text-2xl font-medium">
           Court Shields WhatsApp Group Admins from Vicarious Criminal Liability
          </h2>
          <p className="font-sans text-justify">
          When is one party responsible for the crimes committed by another? Last month, the Kerala High Court[1] weighed in on this question in the context of group administrators or ‘admins’ and group members on WhatsApp. Specifically, the court was asked to consider whether admins can be criminally liable for the illegal content posted by a group member. It convincingly rejected the application of such liability to admins on several grounds such as lack of an admin’s control over group members and the lack of any statutory provision imposing liability on admins for content posted by group members.
          </p>
          <p className="text-lg font-medium decoration-black underline underline-offset-4" >Facts of the Case</p>
          
          <p className="font-sans text-justify">
          Manual v. State of Kerala (<b>“Manual”</b>) concerns child sexual abuse material (<b>“CSAM”</b>) content posted by a WhatsApp group member or ‘member’ onto a group comprising several individuals.
          </p>
          <p className="font-sans text-justify">
          Initially, a WhatsApp group known as “Friends” was created by the admin Manual. He added several individuals including the member in question. Subsequently the member posted the incriminating CSAM content. This triggered the involvement of the police, which charged him with a violation of section 67B of the Information Technology Act, 2000 (<b>“IT Act”</b>) and sections 13 to 15 of the Protection of Children from Sexual Offences Act, 2012 (<b>“POCSO”</b>).[2] Surprisingly however, the police also registered a case against the admin for these offences.
          </p>
          <p className="font-sans text-justify">
          The admin approached the High Court under section 482 of the Code of Criminal Procedure, 1973[3] (<b>“CrPC”</b>) and disputed the imposition of such vicarious criminal liability.[4] He claimed that he lacked any connection with the group member’s actions. According to the admin, there was no evidence to show that he acted in concert with the group member to violate the law; hence there was no basis for imposition of vicarious criminal liability on him
          </p>
          <p className="text-lg font-medium decoration-black underline underline-offset-4" >Court’s Analysis</p>

          <p className="font-sans text-justify">
          While mens rea (“criminal intent”) is usually a prerequisite to a finding of criminal liability the court stresses that this does not apply to all criminal offences under Indian law. An individual may be found guilty of an offence even without a finding of mens rea. There are even criminal offences that an individual may be found guilty of that are committed by someone else. But the court nonetheless sides with the admin in this case, saying that the various threshold requirements for such vicarious criminal liability have not been met.
          </p>
          <p className="font-sans text-justify">
          The main threshold requirement is that a statute must provide for vicarious criminal liability.[5] Where a statute provides for vicarious criminal liability, mens rea can be dispensed with. But given that none of the statutes which the admin is charged with provide for such vicarious criminal liability, it cannot be imposed on him. In any event, the court points out that there is no evidence presented by the prosecution concerning the admin’s criminal liability. There is no evidence in other words of any common intention or prearranged plan or conspiracy between the admin and the group member who uploaded the CSAM.[6] Finally, the court said that there are no other laws on the books which provide for vicarious criminal liability of admins. So, the admin cannot be proceeded against.
          </p>
          <p className="font-sans text-justify">
          Even the statutes that do provide for vicarious criminal liability like section 149 of the Indian Penal Code, 1860 (“IPC”)[7] are not intended to bring within their ambit “innocent bystanders”[8] like the admin that have no connection with the offenders, or the offence being committed. In fact, admins may fairly be said to possess even less power than innocent bystanders or silent onlookers – while such individuals can choose to intervene to assist a victim of a crime in real time, admins only find out about the offence after it has been committed. There is nothing they can do to stop it.
          </p>
          <p className="font-sans text-justify">
          It is for this reason – lack of control – that admins are not liable even under common law principles of vicarious liability. Herein, a principal may be held liable for the civil wrongs committed by an agent.  But in Manual, there is no such respondeat superior[9] connection between the admin and the group member. The admin does not exercise any kind of power or control over the group member’s actions. In fact, the only power that the admin can exercise is the power to add/delete group members.[10]
          </p>

          <p className="text-lg font-medium decoration-black underline underline-offset-4" >Court’s Analysis</p>


          <p className="font-sans text-justify">
          Thus, the court concludes resoundingly that an admin cannot be held liable for the content posted by a WhatsApp group member. While there is a basis in Indian law for vicarious criminal liability that grows out of common law principles of respondeat superior, none of these principles are applicable to the present case.
          </p>
          <p className="font-sans text-justify">
          by Sachin Dhawan [Senior Advisor] and Harsho Sarkar [Associate]
          </p>
          <p className="w-32 h-[2px] mx-auto bg-black"></p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[1]</span> Manual vs State of Kerala CRL.MC NO. 3654 OF 2021
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[2]</span> Section 67B of IT Act concerns punishment for publishing or transmitting material depicting children in sexually explicit act, etc., in electronic form. Section 13 of POCSO proscribes use of children for pornographic purposes. Sections 14 and 15 prescribe the punishment for using children for pornographic purposes and for storage of pornographic material involving children respectively.
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[3]</span> Section 482 of CrPC provides for saving of inherent powers of High Court. (“Nothing in this Code shall be deemed to limit or affect the inherent powers of the High Court to make such orders as may be necessary to give effect to any order under this Code, or to prevent abuse of the process of any Court or otherwise to secure the ends of justice.”)
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[4]</span> …vicarious criminal liability is somewhat of an exception to the general rule of direct personal culpability and is a modern development through statutory provisions.” Manual vs State of Kerala CRL.MC NO. 3654 OF 2021 (Para 8)
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[5]</span> “A vicarious liability can be fastened only by reason of a provision of a statute and not otherwise.” – R. Kalyani v. Janak C. Mehta and Others [(2009) 1 SCC 51]
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[6]</span> Manual vs State of Kerala CRL.MC NO. 3654 OF 2021 [Paras 11 and 13]. See also: Kishor Chintaman Tarone v. State of Maharashtra & Another (2021 ICO 1285).
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[7]</span> Section 149 of IPC provides that every member of an unlawful assembly is guilty of an offence committed in prosecution of a common object.
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[8]</span> Ranchhoddas, R., Thakore, D. K., Thomas, K. T., & Rashid, M. A. (2016). The Indian Penal Code (pp. 891.). LexisNexis.
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[9]</span> A Latin term meaning “Let the master decide”
          </p>
          <p className="font-sans text-justify">
          <span className="text-secondary" >[10]</span> Manual vs State of Kerala CRL.MC NO. 3654 OF 2021 (Para 12)
          </p>
          
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
      </div>
      <QuoteSlider />
    </div>
  );
};

export default CourtShields;
