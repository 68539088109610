import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const MiningMeta = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Mining & Metal"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="Our lawyers with their years of experience understand the macro-economic, geo-political regional risks and opportunities most relevant to your business."
          para2="Not only do we understand the twists and turns of the sector, we also know and track the players, assets, market trends and commodity fundamentals."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default MiningMeta;
