import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import { Link } from "react-router-dom";
const Blog1 = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02241.png"
        BannerTitle=" Updates and Analysis "
        BannerText="The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you. "
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16  mx-auto md:gap-6 md:py-20">
        <div className="col-span-7 flex gap-5 flex-col items-start bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="  text-secondary text-xl leading-relaxed font-medium">
            We are delighted to welcome on board Capt. Sanjay Gahlot (IRS)(R) as
            a Senior Advisor with AP & Partners.
          </h2>
          <p className="  text-justify">
            Capt. Sanjay Gahlot is an army veteran and a former Principal
            Commissioner of Indirect Tax & Customs (IRS 1991, Customs and
            Central Excise). Prior to joining AP & Partners, Capt. Sanjay Gahlot
            served as the Chief Controller of the Government Opium and Alkaloid
            Factories (GOAF). Capt. Sanjay Gahlot brings with him vast
            experience in indirect and direct tax, tax compliance, exchange
            control, customs, import regulation, and anti-smuggling.
          </p>
          <p className="  text-justify">
            Capt. Sanjay Gahlot will provide strategic business and regulatory
            advice to the members and clients of the firm.
          </p>
          <a Target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:6869984135935524864">
            <button className=" font-smedium text-primary  hover:underline duration-500" >
              Read More
            </button>
          </a>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default Blog1;
