import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import TmtPractice from "./TmtPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Tmt = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Technologymedia.jpg"
        BannerTitle="Technology, Media, and Telecommunications"
        BannerText="
        “Our expert advocates cover all the vast spectrums and practices of TMT to deliver precise and accessible solutions.”
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <TmtPractice
          para1="Our TMT practice advises on the full range of issues that technology businesses may need to address as they do business in India. Our clients include start-ups, technology majors, as well as venture capital firms, and private equity sponsors that support them. We consistently deliver deep sectoral knowledge and commercial as well as governmental insights."
          para2="Our unique structure allows us to draw on the strengths of the entire firm for any given matter and consequently offer a broader perspective."
          para3="We also work extensively with our clients as they navigate through rapidly changing laws in India, assisting them in responding to government notices, or evaluating and structuring for business risks arising from upcoming regulations."
          para4="Our team represents not only the largest and most important companies but also new and innovative players across the TMT spectrum."
          para5="Key sectors include satellite - internet, mobility, ed-tech, social media, web3, telecommunications, semiconductors, SaaS, health-tech, and e-commerce."
        />
      </div>

      <div className="pb-10">
        <Career />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Tmt;
