import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceSection = ({ title, pera, img, text, link }) => {
  const navigate = useNavigate();
  return (
    <>
      <div class="overflow-hidden bg-red-400 cursor-pointer rounded-sm relative group">
        <div className="w-full absolute  bottom-0 drop-shadow-2xl z-10 group-hover:z-0 px-6 py-2  group-hover:opacity-100 group-hover:translate-y-0 translate-y-4 pb-6 transform transition duration-150 ease-in-out text-white font-semibold text-2xl bg-[#185A80] bg-opacity-50">
          {title}
        </div>
        <div class="bg-gradient-to-t h-60 from-[#185A80] z-10 opacity-0 group-hover:opacity-100 transition duration-150 ease-in-out cursor-pointer absolute  inset-x-0  pt-30 text-white flex items-end">
          <div>
            <div class="p-4 space-y-3 text-2xl group-hover:opacity-100 group-hover:translate-y-0  translate-y-4 pb-10 transform transition duration-150 ease-in-out">
              <div class="font-bold">{title}</div>

              <div class="text-base text-white ">{pera}</div>
              {text ? (
                <button
                  onClick={() => navigate(link)}
                  className="text-sm underline  font-semibold underline-offset-4"
                >
                  {text}
                </button>
              ) : null}
            </div>
          </div>
        </div>
        <img
          alt=""
          class="grayscale-0 group-hover:grayscale object-cover h-60 w-full object-left-top"
          src={img}
        />
      </div>
    </>
  );
};

export default ServiceSection;
