import React from "react";
import Footer from "../Components/common/Footer";
import InnerBanner from "../Components/common/InnerBanner";
import Navbar from "../Components/common/Navbar";
import Career from "../Components/Home/Career";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const AnandDetail = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      {/* <InnerBanner BackgroundImage="/assets/images/banners/DSC02497.jpg" /> */}

      <div className="px-6 py-10">
        {/* <div className="flex gap-10 py-6"> */}

        {/* </div> */}

        <div className="grid lg:grid-cols-12 grid-cols-6 lg:gap-10">
          <div className="lg:col-span-9 col-span-12 shadow-md rounded-md p-6">
            <div className="py-1 mt-8">
              <h4 className="font-bold py-1 text-6xl text-primary">
                Anand Prasad
              </h4>
              <h4 className="py-1  font-medium text-lg">Senior Partner</h4>
            </div>
            <h1 className="py-1  text-1xl font-semibold">
              Anand Prasad is a corporate/commercial lawyer with more than 3
              decades of experience representing both domestic and international
              clients on a variety of legal and commercial issues within and
              outside India.
            </h1>
            <p className="  py-2 text-justify leading-loose">
              Anand along with former colleagues from Trilegal established AP &
              Partners with the objective of providing high-quality, focused
              advice, and advocacy for complex and challenging situations.
            </p>

            <p className="  py-3 text-justify leading-loose">
              Anand specializes in advising boards and senior management of
              companies on dealing with difficult corporate, commercial,
              governance, and compliance situations. He also provides advisory
              and structuring support to businesses on investment, M&A and
              financing transactions. He has a good understanding of evolving
              government policy and has advised clients on cross-border
              transactions, establishing joint ventures, trading and e-commerce,
              entry strategies, investment structures, competition law and
              employment issues.
            </p>

            <p className="  py-3 text-justify leading-loose">
              <h3>
                In addition, Anand represents clients in arbitrations and in
                various courts and tribunals in matters pertaining to high-value
                disputes which arise in relation to cross-border investments,
                bankruptcy, regulatory issues, corporate fraud, and corruption
                situations.
              </h3>
            </p>

            <p className="  py-3 text-justify leading-loose">
              <h3>
                Anand has been cited in independent legal directories such as
                the IBLJ A-List 2022-23 (List of India’s Top Lawyers), Legal 500
                and Chambers & Partners as a market leader for corporate and
                commercial laws in India for well over a decade.
              </h3>
            </p>
          </div>

          <div className="lg:col-span-3 col-span-12 mx-auto">
            <div className="mt-14">
              <img
                src="/assets/images/Anand.png"
                // className="h-full w-44 shadow-md rounded-lg"
                style={{ objectFit: "scale-down" }}
              />
            </div>

            <div className="py-2 flex gap-3">
              <div className="pt-1">
                <AiOutlineMail className="w-5 h-5 " />
              </div>
              <a
                href="mailto:anand.prasad@appartners.in"
                className="text-secondary font-medium"
              >
                anand.prasad@appartners.in
              </a>
            </div>
            <div>
              <a href="https://www.linkedin.com/in/anand-prasad-0192a6/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
            {/* <h2 className="text-4xl pt-3 pb-2 text-primary font-bold">Expertise</h2> */}
            {/* <h3 className="text-2xl font-bold">Practice(s)</h3>
            <div className="py-4">
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
                Corporate
              </h3>
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
              Dispute Resolution
              </h3>
              <h3 className="border mt-2 border-gray-300 text-secondary   text-lg font-medium rounded px-5 py-1">
              Special Situations
              </h3>
            </div>
            <hr
        style={{
          background: "#D5d5d5",
          color: '#D5d5d5',
          borderColor: '#D5d5d5',
          height: '1px',
        }}
      /> */}
            <div>
              <p className="font-semibold pb-2 pt-5 ">
                Bar Admissions and Memberships:
              </p>
              <p className="font-light">Supreme Court Bar Association (1991)</p>
              <p className="font-light">
                Delhi High Court Bar Association (1993)
              </p>
              <p className="font-light">Bar Council of Delhi (1991)</p>
            </div>
          </div>
        </div>
      </div>

      <Career />

      <Footer />
    </div>
  );
};

export default AnandDetail;
