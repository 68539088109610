import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import FinancialServicesPractice from "./FinancialServicesPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const FinancialServices = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/FinancialServices.jpg"
        BannerTitle="Financial Services"
        BannerText="Do you want to build a rigid foundation for your business? Then find our legal and financial services to refine your structure and build a strong root for the business."
      />

      <div className="md:px-16 px-5 pt-8">
        <FinancialServicesPractice
          para1="We regularly advise our financial services clients on complex regulatory matters. This includes guiding our clients through formal and informal enforcement proceedings, negotiating supervisory agreements, and responding to cease-and-desist orders on their behalf."
          para2="We are able to offer our clients direct insight into the sophisticated financial markets, enabling them to anticipate issues, manage the risks and succeed in their endeavours."
          para3="We also advise new-age financial services and technology companies on the regulatory framework and guidelines of the Reserve Bank of India applicable to their businesses and domestic and offshore transactions."
          para4="We also represent financial services clients in M&A and capital-raising transactions, such as issuances of common and preferred stock, warrants, and subordinated debt, that raise complex securities law issues. Consequently, we are capable of effectively assisting clients in structuring transactions that are successful and cater to regulatory and industry concerns."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default FinancialServices;
