import React from "react";
import { Link } from "react-router-dom";
import InnerBanner from "../common/InnerBanner";
import Career from "../Home/Career";

const card = [
  {
    id: 1,
    icon_box: "/assets/icons/arbitration.png",
    partner_name: " Corporate ",
    Link: "/corporate",
    button: "View More",
  },

  {
    id: 2,
    icon_box: "/assets/icons/litigation.png",
    partner_name: "Litigation,Arbitration and Investigation",
    Link: "/litigation",
    button: "View More",
  },

  {
    id: 3,
    icon_box: "/assets/icons/finance.png",
    partner_name: "Special Situations",
    Link: "/special-situations",
    button: "View More",
  },

  {
    id: 4,
    icon_box: "/assets/icons/consumer.png",
    partner_name: "Employment ",
    Link: "/employment-benefits",
    button: "View More",
  },

  {
    id: 5,
    icon_box: "/assets/icons/infrastructure.png",
    partner_name: "Technology, Media, and Telecommunications",
    Link: "/tmt",
    button: "View More",
  },
  {
    id: 6,
    icon_box: "/assets/icons/estate.png",
    partner_name: "Disputes",
    Link: "/disputes",
    button: "View More",
  },
];

const PracticePage = () => {
  return (
    <div classNameName=" pt-28 pb-10">
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02258.png"
        BannerTitle=""
        BannerText="“The need to find a master advocate with extreme knowledge and brilliance to handle all the intangible situations smoothly ends with AP & Partners.” "
      />

      <div className="px-16">
        <div className="grid grid-cols-3 gap-20  pt-20 pb-10 ">
          {card.map((item) => (
            <div className="container imageClass bg-[#fff]  rounded-md hover:scale-105 duration-200 flex flex-col justify-between ">
              <div className="flex items-center justify-center relative">
                {" "}
                <img src={item.icon_box} alt="" className=" pt-4 " />
              </div>

              <h3 className="text-xl pt-3 text-secondary font-medium capitalize text-center px-2">
                {item.partner_name}
              </h3>

              {/* <div className="flex items-center justify-center pb-1">
                {" "}
                <Link to={item.Link}>
                  {" "}
                  <button className="readmore w-32 inline-block py-1  font-serif italic">
                    {item.button}
                  </button>
                </Link>
              </div> */}
              <div class="overlay bg-secondary">
                <div class="text text-xl items-center">{item.partner_name}
                <Link to={item.Link} className="text-center">
                  {" "}
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="white" class="h-10 w-40">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>

                </Link>
                </div>
                
              </div>
            </div>
          ))}
        </div>
      </div>
      <Career/>
    </div>
  );
};

export default PracticePage;
