import React from "react";
import { Link } from "react-router-dom";

const Card = ({ Title, Image, Description, Date, url }) => {
  console.log("Here is Url", url);
  return (
    <div>
      <div className="flex flex-col border-1 shadow-slate-200 border-slate-300 rounded shadow-lg lg:h-[490px]">
        <div className="relative group">
          <div className="relative">
            <img
              src={Image}
              alt="/"
              className="transition duration-500  rounded-t w-[379px] h-[252px] object-cover object-top              "
            />
            <div className="absolute bg-primary text-secondary bottom-0 text-sm right-0  p-2">
              {Date}
            </div>
          </div>
          <div className=" p-5">
            <div className="text-xl text-secondary font-medium">{Title}</div>
            <div className="text-sm   pt-1 pb-4">{Description}</div>
            <Link to={url}>
              <button className="readmore w-40 inline-block py-1  ">
                Read More
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
