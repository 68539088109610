import React from "react";
import Card from "./Card";
import { Link } from "react-router-dom";
const RecentBlogs = () => {
  return (
    <div>
      <div className="shadow-lg shadow-slate-200 rounded h-[480px]">
        <p className="font-san  font-semibold py-3 md:px-3 px-6">
          Visit Recent Media
        </p>
        <div className="w-full h-[1px] bg-secondary"></div>
        <div className="p-3 flex justify-start items-start gap-2">
          <div className="flex justify-center items-center text-3xl text-bold font-serif   text-primary rounded-full border-primary h-10 w-10 ">
            1
          </div>
          <div className="">
            <p className="text-base text-secondary  font-normal">
              E-commerce rules cast cloud over Amazon, Walmart and local rivals
            </p>{" "}
            <Link
              to="/E-commerce-rules-cast-cloud-over-Amazon-Walmart-and-local-rivals"
              className="cursor-pointer text-sm font-semibold text-secondary  font-serif  hover:font-bold hover:scale-105"
            >
              {" "}
              Read More →
            </Link>
          </div>
        </div>
        <div className="p-3 flex justify-start items-start gap-2 ">
          <div className="flex justify-center items-center text-3xl text-bold font-serif   text-primary rounded-full border-primary h-10 w-10 ">
            2
          </div>
          <div className="">
            <p className="text-base text-secondary font-normal">
              AP & Partners recognized as an award-winning law firm by IBLJ
            </p>{" "}
            <Link
              to="/AP-Partners-recognized-as-an-award-winning-law-firm-by-IBLJ"
              className="cursor-pointer text-sm font-semibold text-secondary  font-serif  hover:font-bold hover:scale-105"
            >
              {" "}
              Read More →
            </Link>
          </div>
        </div>

        <div className="p-3 flex justify-start items-start gap-2 -ml-2">
          <div className="flex justify-center items-center text-3xl text-bold font-serif   text-primary rounded-full border-primary h-10 w-10  ">
            3
          </div>
          <div className="">
            <p className="text-base text-secondary  font-normal">
              India’s New Tech Laws for Social Media
            </p>{" "}
            <Link
              to="/Indias-New-Tech-Laws-for-Social-Media"
              className="cursor-pointer text-sm font-semibold text-secondary  font-serif  hover:font-bold hover:scale-105"
            >
              {" "}
              Read More →
            </Link>
          </div>
        </div>
        <div className="p-3 flex justify-start items-start gap-2 ">
          <div className="flex justify-center items-center text-3xl text-bold font-serif   text-primary rounded-full border-primary h-10 w-10 ">
            4
          </div>
          <div className="">
            <p className="text-base text-secondary  font-normal">
              Our partner Arjun Sinha will be sharing insights on music
              licensing deal
            </p>{" "}
            <Link
              to="/Our-partner-Arjun-Sinha-will-be-sharing-insights-on-music-licensing-deal"
              className="cursor-pointer text-sm font-semibold text-secondary  font-serif  hover:font-bold hover:scale-105"
            >
              {" "}
              Read More →
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentBlogs;
