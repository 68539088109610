import React from "react";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { Link } from "react-router-dom";

const DetailPage = ({ para1, para2, para3, para4, para5, para6 }) => {
  return (
    <div>
      <div className="grid lg:grid-cols-12  grid-cols-1 lg:gap-20 py-10 mx-auto">
        <div className="col-span-12 lg:col-span-8 w-[100%]">
          <p className="py-2 text-justify leading-loose   ">
            {para1}
          </p>
          <p className="py-2 text-justify leading-loose   ">
            {para2}
          </p>
          <p className="py-2 text-justify leading-loose   ">
            {para3}
          </p>
          <p className="py-2 text-justify leading-loose   ">
            {para4}
          </p>
          <p className="py-2 text-justify leading-loose   ">
            {para5}
          </p>
          <p className="py-2 text-justify leading-loose   ">
            {para6}
          </p>
        </div>

        <div className="col-span-12 lg:col-span-4">
          <h3 className="text-2xl font-bold">Practice Contacts</h3>
          <div className="h-1 w-40 bg-primary  mt-1 mb-3"></div>

          <div className="block sm:flex lg:block xl:flex gap-5 py-6">
            {/* <div> */}
            {/* <img
                src="/assets/images/Anand.png"
                className=" shadow-md rounded-lg w-fit object-cover h-48"
              /> */}
            {/* </div> */}
            <div className="py-1">
              <h4 className="font-medium text-lg py-1 text-primary">Anand Prasad</h4>
              <h4 className="py-1 ">Senior Partner</h4>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  anand.prasad@appartners.in
                </a>
              </div>

              <a href="https://www.linkedin.com/in/anand-prasad-0192a6/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>  
          </div>

          <div className="block sm:flex lg:block xl:flex gap-5 py-6">
            {/* <div> */}
            {/* <img
                src="/assets/images/Anand.png"
                className=" shadow-md rounded-lg w-fit object-cover h-48"
              /> */}
            {/* </div> */}
            <div className="py-1">
              <h4 className="font-medium text-lg py-1 text-primary">Arjun Sinha</h4>
              <h4 className="py-1 ">Partner</h4>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  arjun.sinha@appartners.in
                </a>
              </div>

              <a href="https://www.linkedin.com/in/arjun-sinha-288184158/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
          </div>

          <div className="block sm:flex lg:block xl:flex gap-5 py-6">
            {/* <div> */}
            {/* <img
                src="/assets/images/Anand.png"
                className=" shadow-md rounded-lg w-fit object-cover h-48"
              /> */}
            {/* </div> */}
            <div className="py-1">
              <h4 className="font-medium text-lg py-1 text-primary">Harshit Anand</h4>
              <h4 className="py-1 ">Partner</h4>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  Harshit.anand@appartners.in
                </a>
              </div>

              <a href="https://www.linkedin.com/in/hza/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
          </div>

          <div className="block sm:flex lg:block xl:flex gap-5 py-6">
            {/* <div> */}
            {/* <img
                src="/assets/images/Anand.png"
                className=" shadow-md rounded-lg w-fit object-cover h-48"
              /> */}
            {/* </div> */}
            <div className="py-1">
              <h4 className="font-medium text-lg py-1 text-primary">Moksha Bhat</h4>
              <h4 className="py-1 ">Partner</h4>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  moksha.bhat@appartners.in
                </a>
              </div>

              <a href="https://www.linkedin.com/in/mokshabhat"> 
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
          </div>

          <div className="block sm:flex lg:block xl:flex gap-5 py-6">
            {/* <div> */}
            {/* <img
                src="/assets/images/Anand.png"
                className=" shadow-md rounded-lg w-fit object-cover h-48"
              /> */}
            {/* </div> */}
            <div className="py-1">
              <h4 className="font-medium text-lg py-1 text-primary">Sandeep D Das</h4>
              <h4 className="py-1 ">Partner</h4>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium hover:text-primary"
                >
                  Sandeep.das@appartners.in
                </a>
              </div>

              <a href="https://in.linkedin.com/in/sandeep-das-3618658">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </a>
            </div>
          </div>

          {/* <div className="flex items-center justify-center pt-4">
            <Link to="/team">
              {" "}
              <button className="readmore w-44 inline-block py-1  font-serif ">
                Meet The Team
              </button>
            </Link>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default DetailPage;
