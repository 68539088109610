import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import RealEstatePractice from "./RealEstatePractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const RealEstate = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/estate.png"
        BannerTitle="Real Estate"
        BannerText="There is no term in the real estate industry that doesn’t require a legal advisory team to avoid getting stuck in the legal trails. AP & Partners gets you a long-time partner."
      />

      <div className="md:px-16 px-5 pt-8">
        <RealEstatePractice
          para1="We advise and represent developers, investors, lenders, banks, FIs, private equity funds, domestic and foreign / investors & funds, Government entities/agencies, corporate houses, warehousing & logistics companies, land/property owners, contractors, landlords, and tenants."
          para2="We advise on all aspects of property law including various legal and regulatory issues, acquisition or disposal of real estate & joint ventures, leasing, licencing, joint development, mortgage, investments, and dispute resolution including litigation, mediation, and arbitration."
          para3="Our team is well acquainted with every aspect of this sector, providing end-to-end solutions to a variety of transactional, regulatory, and litigation matters relating to this sector."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default RealEstate;
