import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";

const Career = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  const { region, portalId, formId } = props;

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://js.hsforms.net/forms/shell.js";
    document.body.appendChild(script);

    script.addEventListener("load", () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "45108615",
          formId: "24f6c877-6346-4741-b2fd-603d795110c4",
          target: "#hubspotForm",
        });
      }
    });
  }, []);

  return (
    <>
      <div class="bg-gradient-to-r from-secondary via-secondary to-[#185a806e]">
        <div class="md:px-16 px-6 py-8 m-auto">
          <h1 class="text-2xl md:text-4xl text-white font-bold leading-tight mb-2 capitalize text-center">
            Stay updated
          </h1>

          <div class="lg:flex justify-between items-center md:space-y-0 space-y-8">
            <div class="lg:w-6/12 lg:p-0 md:p-7">
              <p class="text-xl text-white ">
                To learn more about our services and to stay updated, please
                subscribe below.
              </p>

              {/* <div class=" flex gap-x-4">
                <Link to="/media">
                  <button
                    className="readmore-btn w-44 inline-block py-1 mt-5   "
                    onClick={openModal}
                  >
                    Read More
                  </button>
                </Link>
              </div> */}
              <div>
                <div id="hubspotForm"></div>
              </div>

              <Transition appear show={isOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-black bg-opacity-70" />
                  </Transition.Child>

                  <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                      >
                        <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                          <Dialog.Title
                            as="h3"
                            className="text-lg font-medium leading-6 text-gray-900"
                          >
                            FORM
                          </Dialog.Title>
                          <div className="mt-2">
                            <div class="mb-4">
                              <label
                                class="block text-gray-700 text-sm font-bold mb-2"
                                for="username"
                              >
                                Your Name
                              </label>
                              <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="name"
                                type="text"
                                placeholder="Name"
                              />
                            </div>

                            <div class="mb-4">
                              <label
                                class="block text-gray-700 text-sm font-bold mb-2"
                                for="email"
                              >
                                Email Id
                              </label>
                              <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="username"
                                type="text"
                                placeholder="Email Id"
                              />
                            </div>
                          </div>

                          <div className="mt-4">
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-900 hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                              onClick={closeModal}
                            >
                              Submit
                            </button>
                          </div>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </Dialog>
              </Transition>
            </div>
            <div class="lg:w-4/12 rounded-full scale-100">
              <img
                class="md:h-[80%] flex mx-auto h-full lg:w-72 w-[80%]"
                alt="image"
                src="assets/images/Support.png"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Career;
