import React from "react";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import { IoIosPeople } from "react-icons/io";
import { IoBarChartOutline } from "react-icons/io5";
import { FaPeopleArrows } from "react-icons/fa";
import { BsNewspaper } from "react-icons/bs";
import Footer from "../Components/common/Footer";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/StaircaseHorizontal.jpg"
        BannerTitle="About Us"
        BannerText="
        “The advances of law apply to all but are available for only those who know how it benefits them with the help of great minds who study it daily.”
        "
      />
      <div className="text-center md:grid grid-cols-12 justify-between items-center md:px-16 px-6 md:pt-10 pt-10 gap-10">
        {/* <div className="col-span-2 space-y-3"> */}
        {/* <p className="uppercase text-5xl text-primary py-1 ">
            Know our story!
          </p>
          <p className="text-xl font-light  text-slate-500  ">
            We carry a pasion for performance marketing and have a knack for
            untangling even the toughest of knots.
          </p>
          <div className="w-28 h-1 bg-gray-500"></div> */}

        {/* <div>
            <img src="/assets/images/about-image.jpg" alt="alt" />
          </div> */}
        {/* </div> */}
        <div className="col-span-12 text-center">
          <p className="py-3  text-base">
            AP & Partners was founded with a simple and compelling vision: A
            team of highly experienced lawyers focused on the most significant
            opportunities and challenges facing our clients.
          </p>
          <p className="py-3  text-base">
            {" "}
            We are chosen by clients because of our experience, depth of
            knowledge and ability to manage difficult situations. We aim to
            deliver exceptional value to our clients by providing clear and
            actionable advice.
          </p>
          <p className="py-3  text-base">
            {" "}
            We are defined by our approach. We like to work on matters that
            require the personal attention of our partners. To that end, our
            associates-to-partner ratio is low. Every mandate that we accept
            involves at least two of our partners so that our clients benefit
            from debate and discussion within the team. As to costs, we prefer
            value billing, and our fee structures aim to maximize value for our
            clients.
          </p>
          <p className="py-3  text-base">
            We are singularly focused on providing our clients with the best
            possible service and advice. Our distinctive structure allows us to
            draw on the strengths of the entire firm for any given matter, and
            consequently offer a broader perspective.
          </p>
          <p className="py-3  text-base">
            Our structured approach and experience enable us to get results for
            our clients on their most critical matters.
          </p>
        </div>
      </div>

      {/* <div className="px-16  py-20">
        <h2 className="text-3xl   capitalize  text-secondary text-center font-medium ">
          Get to know us!
        </h2>
        <div className="h-1 w-24 bg-primary mx-auto mt-4"></div>
        <div className="pt-12 grid grid-cols-4 gap-4 items-center justify-center">
          <div className="flex flex-col justify-center items-center">
            <div className="">
              <IoIosPeople className="text-primary" size={50} />
            </div>
            <div className="text-lg uppercase text-secondary pt-2 font-medium ">
              Leadership
            </div>
            <div className="pt-2    text-secondary text-center px-2">
              Our multicultural leaders have deep startup and enterprize
              experience, worth mobile and expertise .{" "}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div>
              <IoBarChartOutline className="text-primary" size={40} />
            </div>
            <div className="text-lg uppercase text-secondary pt-2 font-medium ">
              Growth
            </div>
            <div className="pt-2    text-secondary text-center px-2">
              Our multicultural leaders have deep startup and enterprize
              experience, worth mobile and expertise .{" "}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div>
              <FaPeopleArrows className="text-primary" size={40} />
            </div>
            <div className="text-lg uppercase text-secondary pt-2 font-medium ">
              Partnerships
            </div>
            <div className="pt-2    text-secondary text-center px-2">
              Our multicultural leaders have deep startup and enterprize
              experience, worth mobile and expertise .{" "}
            </div>
          </div>
          <div className="flex flex-col justify-center items-center">
            <div>
              <BsNewspaper className="text-primary" size={40} />
            </div>
            <div className="text-lg uppercase text-secondary pt-2 font-medium ">
              Leadership
            </div>
            <div className="pt-2    text-secondary text-center px-2">
              Our multicultural leaders have deep startup and enterprize
              experience, worth mobile and expertise .{" "}
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="pt-10">
        <Career />
      </div> */}

<div className="mt-10">
        <h2 className="md:text-4xl text-2xl   text-secondary text-center font-medium">
          Rankings and Recognitions
        </h2>
        <div className="h-1 w-24 bg-primary mx-auto mt-4"></div>
        <div className="max-w-3xl mx-auto grid grid-cols-2 md:grid-cols-4 justify-items-center items-center py-10 space-y-4">
        <img src="/assets/images/ap-recommended-lawyer-2024.png" alt="/" className="w-24" />
        <img src="/assets/images/Legal2024.jpeg" alt="/" className="w-24" />
        <img src="/assets/images/Chamber.jpeg" alt="/" className="w-36" />
        <img src="/assets/images/ISO.jpeg" alt="/" className="w-24" />
        </div>
        <div className="max-w-2xl mx-auto grid grid-cols-2 md:grid-cols-3 justify-items-center items-center py-10 space-y-4">
        <img src="/assets/images/rank1.png" alt="/" className="w-24 pt-4" /> 
        <img src="/assets/images/rank4.jpg" alt="/" className="w-28" />
        <img src="/assets/images/IFLR2023.png" alt="/" className="w-24" />
         
        </div>
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default AboutUs;
