import React from "react";
import ServiceSection from "../Home/ServiceSection";

const JoinPage = () => {
  return (
    <div className="pt-12">
      <div className="flex flex-col items-center align-center text-center justify-center">
        <h2 className="md:px-16 px-6  font-bold md:text-center md:text-2xl text-xl  text-justify text-secondary pb-5 ">
        AP & Partners is dedicated to providing advice and expertise at the highest levels and to achieving extraordinary results for our clients.
          {/* <span className="text-primary"> AP & Partners</span> */}
        </h2>
        <p className="md:px-28 px-6 text-justify pt-3 pb-6 leading-loose ">
        We seek individuals who are talented, motivated and committed in order to maintain our record of excellence. Our associates, paralegals, and administrative staff are critical to this mission. Our lawyers and staff work closely together across departments comprising an elite legal team. We are committed to fostering a diverse and inclusive work environment.

          {/* <span className="text-secondary font-semibold">
            {" "}
            careers@appartners.in
          </span> */}
        </p>
        <p className="md:px-28 px-6 pb-6 text-justify leading-loose  ">
        We are an equal opportunity employer and adhere to an employment policy that prohibits discriminatory practices or harassment against applicants or employees based on any legally impermissible factor(s) including, but not necessarily limited to, race, colour, religion, creed, sex, national origin, age, citizenship, marital status, sexual orientation, gender, gender identity or expression, disability or any protected military or veteran status.

        </p>
        <p className="md:px-28 px-6 pb-10 text-justify leading-loose ">
        If you are interested in applying for a position or an internship please send in your resume, a copy of your law school transcript and a cover letter indicating your interest in a practice area to contact us at
        <a  href="mailto:careers@appartners.in" className="font-semibold cursor-pointer"> careers@appartners.in</a> 
        </p>
      </div>
      {/* <div className="grid md:grid-cols-3 px-16 gap-8 pt-2 pb-5">
        <ServiceSection
          title="Careers"
          pera="Our lawyers play a vital role in shaping the future and growth of our firm. We make sure good work is always recognized and we provide ample career progression opportunities."
          img="/assets/images/serviceThree.png"
        />
        <ServiceSection
          title="Our Culture"
          pera="We have a true “one firm” culture and although we have offices across nine jurisdictions, we work seamlessly together and pride ourselves on having a collaborative approach across the firm."
          img="/assets/images/practice.jpg"
        />
        <ServiceSection
          title="Graduates "
          pera="Our people are at the heart of our success and through their talent, passion and relentless commitment, we have become the go-to law firm for creative and innovative solutions in the Middle East and North Africa region. "
          img="/assets/images/practiceThree.jpg"
        />
      </div> */}
    </div>
  );
};

export default JoinPage;
