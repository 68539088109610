import React from 'react'
import Footer from '../Components/common/Footer'
import Navbar from '../Components/common/Navbar'
import ContactPage from '../Components/contact/ContactPage'

const ContactUs = () => {
  return (
    <div>
      <Navbar />
      <ContactPage />
      <Footer />
    </div>
  )
}

export default ContactUs