import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import TechnologyPractice from "./TechnologyPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Technology = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/technology.png"
        BannerTitle="Emerging Technologies"
        BannerText="The legal entities are reforming the policies to refine the technology sector. One must find a legal expert to work as a secured service provider in the sector."
      />

      <div className="md:px-16 px-5 pt-8">
        <TechnologyPractice
          para1="Emerging technologies present unique challenges such as issues relating to intellectual property, intermediary liability, privacy, data protection, and security, along with the incorporation of the same into various contracts and agreements."
          para2="Our team has helped international corporations in establishing their operations in India and we have strong expertise in the sub-verticals of edu-tech, privacy, gaming, payments, cybersecurity, AI, satellite-based communications, electronic vehicles, and e-commerce. Companies turn to us for technology transfer deals, website infringement, issues relating to safe-harbour protection, corporate joint ventures, employment, and regulatory compliances. We provide creative and practical solutions to our clients by rigorously combining industry knowledge, practical experience, and comprehensive legal research."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Technology;
