import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import EducationPractice from "./EducationPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Education = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/education.png"
        BannerTitle="Education"
        BannerText="
        The wisdom of brains also needs experts from legal domains to get them out of political and social scrutiny. With us, you get perfectionists.
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <EducationPractice
          para1="With the country set to capitalise on its burgeoning demographic dividend over the next decade, the Education industry presents significant growth opportunities. The sector, however, remains highly regulated and is riddled with complex challenges. Education at the primary, secondary and higher level is highly regulated on matters such as permission to establish educational institutions, fees, hiring of teachers, reservations for certain sections of society etc. The process of affiliations and approval of curricula is also onerous, considering that different regulators oversee different courses (For ex. UGC, AICTE, BCI, MCI etc.)."
          para2="Our team provides comprehensive legal services to a broad spectrum of clients from entrepreneurial Ed-tech start-ups to nationally renowned public and private universities, colleges, private schools, examination boards such as the CISCE and accrediting agencies. Complimented by our other practice areas, we synergise our strength, which enables us to address the full range of legal and practical issues involved in this highly regulated industry."
          para3="Our team comprises of leaders from varied legal disciplines who understand the unique nature and needs of educational institutions. Our lawyers regularly work together to define best practices, counsel clients on critical legal issues and defend them in courts, arbitrations, mediations and before accrediting agencies, licensing bodies and government agencies. "
          para4="Given our vast commercial practice, we provide our clients with the best way to structure their educational concerns so as to achieve the fine balance between the need for financial returns to investors, compliance with Indian regulations and setting up an ecosystem that attracts quality students."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Education;
