import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import InfrastructurePractice from "./InfrastructurePractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Infrastructure = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Infrastructure&Transport.jpg"
        BannerTitle="Infrastructure & Transport"
        BannerText="In the race to grow and achieve more and more, the importance of legal terms is paramount. Our sector-based specialists can handle the formalities and terms precisely."
      />

      <div className="md:px-16 px-5 pt-8">
        <InfrastructurePractice
          para1="We help our clients to plan, build, finance, invest in, manage, and operate transport infrastructure. Working with both national and international clients helps us bring international and local experience and expertise to deliver complex and innovative projects. From labour regulatory compliance to real estate, commercial advice and beyond, we work by drawing on cross-practice knowledge from across the firms, to deliver a seamless, coordinated service."
          para2="We cover the full asset life cycle from creation to acquisition, the raising of sponsor or debt capital, restructuring and portfolio management and asset sales. We take into account the critical interplay of regulatory issues, political concerns, and market susceptibilities that dominate and drive investments in this sector."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Infrastructure;
