import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const Defence = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Defence"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="As global demand for defense, aerospace products and new technologies increases, aerospace suppliers and defense contractors face an increasingly complex and often contradictory, regulatory environment, diverse economic conditions in the markets in which they operate, global supply chain risks, the need for privacy security and intellectual property protection."
          para2="With decades of experience and being well connected in the industry, we help our aerospace and defense clients successfully manage these market and regulatory challenges."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Defence;
