import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import CommunicationPractice from "./CommunicationPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Communication = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Spacetechnologies&Telecommunication.jpg"
        BannerTitle="Space Technologies and Telecommunications"
        BannerText="Are you stuck in between the trials? Do you want to find a legal partner to ensure your get away from any legal deal? Then find AP & Partners at your service. "
      />

      <div className="md:px-16 px-5 pt-8">
        <CommunicationPractice
          para1="Our team advises many of the world’s leading providers of communication services, including broadband internet access providers, cable operators, content providers, mobile and fixed telecommunications carriers, and satellite companies, as well as investors and lenders, on the full spectrum of regulatory, litigation, and transactional matters that have shaped the industry. We constantly deliver deep sectoral and commercial knowledge as well as governmental insights. We also provide feasible and commercially viable solutions while adhering to the legal and regulatory constraints in the sector."
          para2="We assist our clients to navigate through the rapidly changing laws in India, assist them in responding to government notices, or evaluate and structure for business risks arising from upcoming regulations."
          para3="Much of our communications work centres around the regulatory issues that apply to telecom services in India. We work with offshore companies to assist them in providing services or building operations in India. As the communications industry, especially in India, builds on products and ideas in a regulatory flux, our team from time to time engages with state and central governments to recommend the development of risk-appropriate regulatory frameworks."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Communication;
