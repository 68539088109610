import React from "react";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import TeamPage from "../Components/team/TeamPage";
import Career from "../Components/Home/Career";

const Team = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/teamOne.png"
        BannerTitle="Our Team"
        BannerText="“A mind with the knowledge of the law,
        can defeat without touching a single hair.”
       "
      />
      <TeamPage />
      {/* <Career /> */}
      <Footer />
    </div>
  );
};

export default Team;
