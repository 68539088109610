import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Litigation = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Litigation,Arbitration And Investigation"
        BannerText="
        “To represent the clients in the court and defend them with our best capabilities, our litigation team is chosen because of their intense research and display skills.”
        "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="Our multi-disciplinary litigation practice represents clients facing unusually challenging, high-stakes legal disputes. Our clients have entrusted us on a wide range of matters, with an emphasis on securities, corporate governance and creditor’s and shareholder’s rights. "
          para2="The deep experience of our litigator’s coupled with the broader capabilities of the firm gives us a unique ability to help our clients navigate through difficult situations."
          para3="We have acted for many of the world’s leading companies on their most significant disputes and investigations. Many of the cases we draw public attention due to the high-profile nature of the work, as well as impact it might have for our clients."
          para4="We look to provide advice that is both practical and commercial."
        />
      </div>
      <div className="pb-10">
        <Career />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Litigation;
