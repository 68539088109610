import React from 'react'
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import WhiteCollarPractice from "./WhiteCollarPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from '../Components/Home/Career';

const WhiteCollarDefence = () => {
  return (
    <div>
    <Navbar />
    <InnerBanner
      BackgroundImage="/assets/images/UpdatedImg/Whitecollardefence.jpg"
      BannerTitle=" White Collar Defence"
      BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
    />

    <div className="px-16 pt-8">
      <WhiteCollarPractice
        para1="We work with clients on matters concerning complex business, financial and economic offences including securities fraud, money laundering, anti-bribery, foreign investment-related offences, unauthorized disclosure of trade secrets and confidential information, criminal defamation and matters involving contravention or violation of laws & regulations attracting criminal liability."
       para2="We advise clients on the full range of business crime issues, ranging from internal and regulatory investigations to high-profile disputes. We have a leading criminal defence capability covering anti-corruption and bribery, anti-money laundering, fraud (financial and tax), antitrust, sanctions, and insider dealing. Additionally, we have extensive experience in handling investigations and associated employee issues as well."
       para3="The deep experience of our litigators in criminal law with their comprehensive knowledge, experience and understanding of the investigation process, bail, custody, and trial procedures help our clients with a formidable defence. We regularly represent clients before investigating agencies including the Central Bureau of Investigation (CBI), Crime Branch, Economic Offences Wing (EOW), Special Investigation Team (SIT), Serious Fraud Investigation Office, Ministry of Corporate Affairs (SFIO), Enforcement Directorate (ED) and the Department of Revenue Intelligence (DRI)."
      />
    </div>

    {/* <div className="pb-10">
    <Career/>
    </div> */}

    <QuoteSlider />

    <Footer />
  </div>
  )
}

export default WhiteCollarDefence