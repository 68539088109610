import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const Arbitration = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Arbitration"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="Our lawyers regularly act on high-value international arbitrations and we have a long track record of achieving successful outcomes for our clients. Our lawyers also regularly act as arbitrators in commercial arbitrations."
          para2="We have significant experience of conducting arbitration proceedings under all key institutional, including those of the London Court of International Arbitration, International Chamber of Commerce, and Singapore International Arbitration Centre
          as well as the UNCITRAL Rules."
          para3="We represent parties in arbitrations arising in a broad range of industry sectors, including construction, energy, international trade and investments, media and communications, technology and telecommunications. "
          para4="We are particularly recognised for our expertise in shareholder and investment disputes. Our experience also includes court litigation in support of arbitration."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Arbitration;
