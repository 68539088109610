import React from "react";
import { Link } from "react-router-dom";
import InnerBanner from "../common/InnerBanner";
import Career from "../Home/Career";
import StayUpdated from "../Home/StayUpdated";

const card = [
  {
    id: 1,
    icon_box: "/assets/icons/mining.png",
    partner_name: "Automotives",
    Link: "/automotive",
    button: "View More",
  },

  {
    id: 2,
    icon_box: "/assets/icons/commercial.png",
    partner_name: "Satellite and Communications ",
    Link: "/communication-and-media",
    button: "View More",
  },

  {
    id: 3,
    icon_box: "/assets/icons/advisory.png",
    partner_name: "Education",
    Link: "/education",
    button: "View More",
  },

  {
    id: 4,
    icon_box: "/assets/icons/litigation.png",
    partner_name: "Energy and Natural Resources",
    Link: "/energy-and-natural-resources",
    button: "View More",
  },

  {
    id: 5,
    icon_box: "/assets/icons/enforcement.png",
    partner_name: "Consumer and Retail",
    Link: "/consumer-retail",
    button: "View More",
  },

  {
    id: 6,
    icon_box: "/assets/icons/arbitration.png",
    partner_name: "Industrials and manufacturing",
    Link: "/industrial-manufacturing",
    button: "View More",
  },

  {
    id: 7,
    icon_box: "/assets/icons/tech.png",
    partner_name: "Infrastructure and Transport",
    Link: "/infrastructure-transport",
    button: "View More",
  },

  {
    id: 8,
    icon_box: "/assets/icons/regulatory.png",
    partner_name: "Health care and Life Science",
    Link: "/health-care-and-life-science",
    button: "View More",
  },

  {
    id: 9,
    icon_box: "/assets/icons/estate.png",
    partner_name: "Real Estate",
    Link: "/real-estate",
    button: "View More",
  },

  {
    id: 10,
    icon_box: "/assets/icons/arbitration.png",
    partner_name: "Private Equity and venture Capital",
    Link: "/pivate-equity",
    button: "View More",
  },

  {
    id: 11,
    icon_box: "/assets/icons/defence.png",
    partner_name: "Technology and Innovation",
    Link: "/technology",
    button: "View More",
  },
  {
    id: 12,
    icon_box: "/assets/icons/report.png",
    partner_name: " Financial Services",
    Link: "/financial-services",
    button: "View More",
  },
  {
    id: 13,
    icon_box: "/assets/icons/social-media.png",
    partner_name: "Entertainment Sports and Media",
    Link: "/entertainment-sports",
    button: "View More",
  },
  {
    id: 14,
    icon_box: "/assets/icons/home-security.png",
    partner_name: " White Collar Defence",
    Link: "/white-collar-defence",
    button: "View More",
  },
];

const SectorsPage = () => {
  return (
    <div classNameName=" pt-28 pb-10">
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02258.png"
        BannerTitle="Sectors"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="px-16">
        <div className="grid grid-cols-3 gap-10  pt-20 pb-10 ">
          {card.map((item) => (
            <div className=" container imageClass bg-[#fff]  rounded-md hover:scale-105 duration-200 flex flex-col justify-between">
              <div className="flex items-center justify-center relative">
                {" "}
                <img src={item.icon_box} alt="" className=" pt-4 " />
              </div>

              <h3 className="text-xl pt-3 text-secondary font-medium capitalize text-center px-2">
                {item.partner_name}
              </h3>
              {/* 
              <div className="flex items-center justify-center pb-1">
                {" "}
                <Link to={item.Link}>
                  {" "}
                  <button className="readmore w-32 inline-block py-1  font-serif italic">
                    {item.button}
                  </button>
                </Link>
                
              </div> */}
              <div class="overlay bg-secondary">
                <div class="text text-xl items-center">
                  {item.partner_name}
                  <Link to={item.Link} className="text-center">
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="white"
                      class="h-10 w-40"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12.75 15l3-3m0 0l-3-3m3 3h-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Career />
    </div>
  );
};

export default SectorsPage;
