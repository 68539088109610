import React from "react";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import { Link } from "react-router-dom";
const Blog8 = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02241.png"
        BannerTitle="Updates and Analysis"
        BannerText="The AP & Partners team believes in transmitting their knowledge and guidance to all. Read, analyze, learn, and grab what is right for you."
      />
      <div className="md:grid md:grid-cols-10 grid-cols-2 md:container-ap md:px-16  mx-auto md:gap-6 md:py-20">
        <div className="col-span-7 flex gap-5 flex-col items-start bg-[#F9F8F9] p-8 rounded shadow-slate-400 shadow">
          <h2 className="  text-secondary text-xl leading-relaxed font-medium">
          India’s New Tech Laws for Social Media
         </h2>
          <p className="  text-justify">
          Days after the government notified the new I.T. rules for social media intermediaries & digital media platforms, global & domestic social media platforms came together to discuss the new rules. Mugdha Variyar speaks with Arjun Sinha, Partner at AP & Partners</p>
          {/* <p className="  text-justify">
            Capt. Sanjay Gahlot will provide strategic business and regulatory
            advice to the members and clients of the firm.
          </p> */}
          <a Target="_blank"href="https://www.linkedin.com/posts/young-turks_indias-new-tech-laws-for-social-media-activity-6772884028405153792-1EDq">
            <button className=" font-smedium text-primary  hover:underline duration-500">
              Read More
            </button>
          </a>
        </div>
        <div className="col-span-3 md:pt-0 pt-6 pb-12">
        <RecentBlogs />
        </div>
        
      </div>
      <QuoteSlider />
      <Footer />
    </div>
  );
};

export default Blog8;
