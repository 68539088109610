import React from "react";
import Footer from "../Components/common/Footer";
import QuoteSlider from "../Components/common/QuoteSlider";
import Navbar from "../Components/common/Navbar";
import InnerBanner from "../Components/common/InnerBanner";
import { Link } from "react-router-dom";
import Card from "../Components/common/blogs/Card";
import RecentBlogs from "../Components/common/blogs/RecentBlogs";
const Knowledge = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02248.png"
        BannerTitle="Knowledge and Analysis"
        BannerText="
        We have a proficient team of masterful legal advisors and advocates to find you rigid and legitimate solutions. Our analytical team researches and then figures out the perfect strategy.
        "
      />

      <div>
        <div className="grid lg:grid-cols-3 px-10  md:grid-cols-2  grid-cols-1 gap-8 pt-20 mx-auto">
          <Card
            Image="/assets/images/blog/knowledge1.jpeg"
            Title="India Business Law Journal 2023-2024 A-List Icon "
            Description="Our Senior Partner Anand Prasad continues to be recognized as an Icon in India Business Law Journal's A-List of top....."
            Date="22/12/2023"
            url="/Knowledge-Detail-Page"
          />
          <Card
            Image="/assets/images/blog/knowledge.png"
            Title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
            Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
            Date="24/09/2021"
            url="/Knowledge-Detail-Page"
          />
          <Card
            Image="/assets/images/blog/knowledge.png"
            Title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
            Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
            Date="24/09/2021"
            url="/Knowledge-Detail-Page"
          />
        </div>
        <div className="grid lg:grid-cols-3  gap-8 md:grid-cols-2 grid-cols-1 px-10 py-20 mx-auto ">
        <Card
            Image="/assets/images/blog/knowledge.png"
            Title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
            Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
            Date="24/09/2021"
            url="/Knowledge-Detail-Page"
          />
          <Card
            Image="/assets/images/blog/knowledge.png"
            Title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
            Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
            Date="24/09/2021"
            url="/Knowledge-Detail-Page"
          />
          <Card
            Image="/assets/images/blog/knowledge.png"
            Title="Lorem Ipsum is simply dummy text of the printing and typesetting industry. "
            Description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"
            Date="24/09/2021"
            url="/Knowledge-Detail-Page"
          />
        </div>
      </div>

      <QuoteSlider />

      {/* <div className="max-w-[900px] mx-auto flex gap-4 px-5 md:px-0 py-16">
        <div className="text-primary text-5xl">
          <img src="/assets/images/quotation.png" alt="quotes" width={120} />
        </div>
        <div className=" text-2xl text-secondary  pt-10">
          AP & Partners is a strong partner for anyone looking to understand and
          navigate the Indian market..
        </div>
      </div> */}

      <div className="mt-10">
        <h2 className="md:text-4xl text-2xl   text-secondary text-center font-medium">
          Rankings and Recognitions
        </h2>
        <div className="h-1 w-24 bg-primary mx-auto mt-4"></div>
        <div className="max-w-3xl mx-auto grid grid-cols-2 md:grid-cols-4 justify-items-center items-center py-10 space-y-4">
          <img src="/assets/images/rank5.png" alt="/" className="w-36" />
          <img src="/assets/images/rank3.png" alt="/" className="w-24" />
          <img src="/assets/images/rank2.jpg" alt="/" className="w-28" />
          <img src="/assets/images/rank1.png" alt="/" className="w-24 pt-4" />
        </div>
        <div className="max-w-2xl mx-auto grid grid-cols-2 md:grid-cols-3 justify-items-center items-center py-10 space-y-4">
          <img src="/assets/images/rank4.jpg" alt="/" className="w-28" />
          <img src="/assets/images/ranking-3.jpg" alt="/" className="w-36" />
          <img src="/assets/images/rank6.jpg" alt="/" className="w-28 pt-4" />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Knowledge;
