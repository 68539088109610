import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import { Link } from "react-router-dom";
import { BsPhone } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import QuoteSlider from "../Components/common/QuoteSlider";

const Commercial = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Commercial"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="grid grid-cols-12 gap-16 py-10 mx-auto px-16">
        <div className="col-span-7">
          <p className="py-2 text-justify leading-loose italic font-sans ">
            We advise leading corporations and financial institutions on the
            full range of commercial issues and support them on their complex,
            challenging and high-value commercial contracts and projects.
          </p>
          <p className="py-2 text-justify leading-loose italic font-sans ">
            We are valued for the quality of our legal expertise and our ability
            to negotiate robust agreements. We understand the business
            objectives of our clients and look to address issues in a pragmatic
            and commercially minded manner.
          </p>
          <p className="py-2 text-justify leading-loose italic font-sans ">
            Our lawyers are frequently engaged in commercial matters that have
            significant tax, regulatory and litigation issues.
          </p>

          <p className="text-secondary font-medium capitalize py-3">
            {" "}
            Core practice areas include:
          </p>

          <ul className="px-5 ">
            <li className="list-disc py-1 capitalize">
              commercial real estate;
            </li>
            <li className="list-disc py-1 capitalize">
              high value supply and services agreements;
            </li>
            <li className="list-disc py-1 capitalize">
              executive compensation;
            </li>
            <li className="list-disc py-1 capitalize">
              financial services; and
            </li>
            <li className="list-disc py-1 capitalize">outsourcing projects.</li>
          </ul>
        </div>

        <div className="col-span-5 ">
          <h3 className="text-2xl font-bold">Practice Contacts</h3>
          <div className="h-1 w-40 bg-primary  mt-1 mb-3"></div>

          <div className="flex gap-5 py-6">
            <div>
              <img
                src="/assets/images/Anand.png"
                className="h-full w-full shadow-md rounded-lg"
              />
            </div>
            <div className="py-1">
              <h4 className="font-medium py-1 text-primary">Anand Prasad</h4>
              <h4 className="py-1 italic">Partner</h4>
              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <BsPhone className="w-5 h-5" />
                </div>
                <a href="tel:2321234567" className="text-secondary font-medium">
                  +91-1142594444
                </a>
              </div>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium"
                >
                  anand.prasad@appartners.in
                </a>
              </div>

              <Link to="/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </Link>
            </div>
          </div>

          <div className="flex gap-5 py-6">
            <div>
              <img
                src="/assets/images/Moksha.png"
                className="h-full w-full shadow-md rounded-lg"
              />
            </div>
            <div className="py-1">
              <h4 className="font-medium py-1 text-primary">Moksha Bhat</h4>
              <h4 className="py-1 italic"> Partner</h4>
              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <BsPhone className="w-5 h-5" />
                </div>
                <a href="tel:2321234567" className="text-secondary font-medium">
                  +3456123-4567
                </a>
              </div>

              <div className="py-2 flex gap-3">
                <div className="pt-1">
                  <AiOutlineMail className="w-5 h-5 " />
                </div>
                <a
                  href="mailto:anand.prasad@appartners.in"
                  className="text-secondary font-medium"
                >
                  moksha.bhat@appartners.in
                </a>
              </div>

              <Link to="/">
                <div className="py-2 flex gap-3 text-secondary font-medium hover:text-primary">
                  <div class="w-7 h-7 rounded bg-secondary flex  justify-center items-center text-white ">
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fab"
                      data-icon="linkedin-in"
                      class="w-5"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        fill="currentColor"
                        d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"
                      ></path>
                    </svg>
                  </div>

                  <div>View Profile</div>
                </div>
              </Link>
            </div>
          </div>

          <div className="flex items-center justify-center pt-4">
            <Link to="/team">
              {" "}
              <button className="readmore w-44 inline-block py-1  font-serif italic">
                Meet The Team
              </button>
            </Link>
          </div>
        </div>
      </div>
      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Commercial;
