import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const WhiteCollar = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="White Collar & Enforcement Actions"
        BannerText="Are you looking for a representative in front of the investigation agency? AP & Partners have a team of experts to deal with any situation and get you out of anything legally. "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="Our team of experienced lawyers brings vast expertise and judgment to legal representation in white collar defence and regulatory enforcement. Our team has significant experience in all aspects of corporate criminal defence and enforcement-related litigation, including internal investigations, management of parallel criminal and civil proceedings and extraditions."
          para2="We represent clients facing investigation or prosecution by central, state and local authorities, including the Enforcement Directorate, the Central Bureau of Investigation, the securities Exchange Board of India and other law enforcement authorities in India."
          para3="We regularly act on high-profile investigations and enforcement actions in sensitive criminal and civil investigations. Our deep experience in the representation of clients facing parallel civil and criminal investigations results in a robust and well-coordinated team of specialists able to defend clients on all fronts."
          para4="We frequently counsel clients long before government action, including regulatory counseling on industry risk areas. Our lawyers understand how prosecutors, investigators and regulators approach industry-specific legal and regulatory issues, which informs our judgment and ability to effectively advise our clients."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default WhiteCollar;
