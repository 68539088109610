import React from "react";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import PracticePage from "../Components/practice/PracticePage";
import SectorsPage from "../Components/practice/SectorsPage";

const Sectors = () => {
  return (
    <div>
      <Navbar />
      <SectorsPage/>
      <Footer />
    </div>
  );
};

export default Sectors;