import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import IndustrialPractice from "./IndustrialPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from "../Components/Home/Career";

const Industrial = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/UpdatedImg/Industry&Manufacturing.jpg"
        BannerTitle="Industry and Manufacturing"
        BannerText="You must undergo legal formalities if you are looking forward to growing or expanding your industry. AP & Partners got the perfectionists to ensure its legitimacy."
      />

      <div className="md:px-16 px-5 pt-8">
        <IndustrialPractice
          para1="Our team has an in-depth understanding of the key business challenges for industrial companies which provide both capital goods and raw materials for national and worldwide production facilities. Our team is highly experienced in managing the risks associated with supply chain failure and proactively addressing upcoming regulations which may affect our clients. We also work with various industry leaders in various sectors and provide services to them to align their strategic and operational business needs."
          para2="The firm’s combined excellence across corporate and dispute practices is distinctive. Combined with an outstanding record of advising leading industrial companies makes it a trusted adviser to many of the sector’s biggest companies."
          para3="Our depth of understanding of individual sectors, strong local connections, global perspective and cross-practice strength can benefit clients as they navigate regulatory demands and local environments."
        />
      </div>

      {/* <div className="pb-10">
        <Career />
      </div> */}

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Industrial;
