import React from "react";
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import DetailPage from "../Components/practice/DetailPage";
import QuoteSlider from "../Components/common/QuoteSlider";

const Regulatory = () => {
  return (
    <div>
      <Navbar />
      <InnerBanner
        BackgroundImage="/assets/images/banners/DSC02269.png"
        BannerTitle="Regulatory & Compliance"
        BannerText="I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsum I am a dummy text lorem ipsumI am a dummy text lorem ipsumI am a dummy text lorem ipsumv "
      />

      <div className="md:px-16 px-5 pt-8">
        <DetailPage
          para1="With our extensive knowledge of local regulations and our Multi–Jurisdictional capabilities, we are well positioned to help our clients deal effectively and creatively with regulatory and compliance issues."
          para2="We help our clients in understanding their responsibilities, manage risk and lessen the impact of complex regulatory regimes on their transactions or day-to-day operations."
          para3="Our team regularly assists clients in engaging with state and central regulators, governmental bodies, elected representatives, industry associations, and other stakeholders in informing the regulatory landscape."
          para4="In addition, we work with internal teams to develop compliance frameworks to ensure regulatory obligations translate into real world business practices to help prevent litigation or enforcement risks."
        />
      </div>

      <QuoteSlider />

      <Footer />
    </div>
  );
};

export default Regulatory;
