import React from "react";

const Popup = () => {
  return (
    <div className=" text-secondary   md:p-10  p-4">
      <p className="pb-3 font-medium  text-justify leading-relaxed">
        The Bar Council of India imposes restrictions on websites by lawyers/
        law firms, and prohibits us from soliciting work or advertising in any
        manner. By agreeing to enter our website, you acknowledge that:
      </p>
      <ul className="list-disc list-inside pt-4  leading-relaxed">
        <li>
          We are providing this information on your request, and that you wish
          to gain information about AP & Partners, its areas of practice and
          attorneys;
        </li>
        <li>
          This website does not constitute an advertisement, communication,
          solicitation, or inducement by any member of our firm;
        </li>
        <li>
          The receipt or use of our website does not create any lawyer-client
          relationship; and
        </li>
        <li>Nothing on this site constitutes legal advice.</li>
      </ul>
    </div>
  );
};

export default Popup;
