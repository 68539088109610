import React from 'react'
import InnerBanner from "../Components/common/InnerBanner";
import Footer from "../Components/common/Footer";
import Navbar from "../Components/common/Navbar";
import EntertainmentSportsPractice from "./EntertainmentSportsPractice";
import QuoteSlider from "../Components/common/QuoteSlider";
import Career from '../Components/Home/Career';

const EntertainmentSports = () => {
  return (
<div>
    <Navbar />
    <InnerBanner
      BackgroundImage="/assets/images/UpdatedImg/Entertainment,esports&media.jpg"
      BannerTitle="Entertainment, eSports, and Media"
      BannerText="Are you looking for a legal consultant? Do you want to play professionally and profitably in the industry? Then find us with ears and perfect solutions to your problems. "
    />

    <div className="md:px-16 px-5 pt-8">
      <EntertainmentSportsPractice
        para1="eSports and fantasy gaming are upcoming areas of practice in the country from a business and legal standpoint. Our team is able to provide the finest legal advice on this nascent practice."
       para2="Our multidisciplinary approach with top-notch practices in dispute resolution, intellectual property, data protection, and corporate and other disciplines which are key to our clients, aids our eSports, media, and entertainment practice and has ensured that our client’s interests are prioritised upfront."
       para3="We first engage with our clients for an in–depth understanding of their business models and proposals. This facilitates a personalised approach to structuring their offerings in a legally compliant manner, building the right processes, identifying multiple stakeholder roles, and building checks and balances as necessary."
       para4="We provide innovative and cutting-edge solutions within the legal and regulatory framework throughout the incubation, development, production, and distribution cycle of a creative product and also through due diligence, and negotiations right until the closing of an acquisition."
      />
    </div>

    {/* <div className="pb-10">
    <Career/>
    </div> */}

    <QuoteSlider />

    <Footer />
  </div>
  )
}

export default EntertainmentSports